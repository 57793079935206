import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import { get, post } from "../../components/api";
import { SweetAlert } from "../../helpers/helper";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  vehicleId: Yup.string().required("Please select vehicle."),
  supportType: Yup.string().required("Please select support type."),
  message: Yup.string()
  .required("Message is required.")
  .test(
    "word-count",
    "Message should not exceed 200 words.",
    (value) => {
      if (value) {
        // Split the value by space and filter empty strings
        const wordCount = value.trim().split(/\s+/).filter((word) => word !== "").length;
        return wordCount <= 200;
      }
      return true;
    }
  ),
});

const SupportTicket = (props) => {
  const [vehicleData, setVehicleData] = useState([]);
  const [selectVehicle, setSelectVehicle] = useState({});
  const supportData = {
    vehicleId: '',
    supportType: "",
    message: "",
  };

  // support types
  const supportOptions = [
    {
      _id: 1,
      name: "GPS related issue",
    },
    {
      _id: 2,
      name: "Sensor related issue",
    },
    {
      _id: 3,
      name: "Account related issue",
    },
    {
      _id: 4,
      name: "Report not generated",
    },
    {
      _id: 5,
      name: "Other issue",
    },
  ];

  useEffect(() => {
    const getVehicles = async ()=> {
      const vehiclesData = await get("/admin/vehicles-list");
      if (vehiclesData.status == 200) {
        setVehicleData(vehiclesData.data);
      } else {
        setVehicleData([]);
      }
    };
    getVehicles();
  }, [])

  const formik = useFormik({
    initialValues: supportData,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const response = await post("/admin/support-ticket", values);
        if (response.status == 200) {
          SweetAlert(response.message, false);
          setTimeout(() => {
              props.onHide(true);
          }, 2000);
        } else {
          SweetAlert(response.message, true);
        }
      } catch (error) {
        console.log(error.message);
      }finally {
        setSubmitting(false); 
      }
    },
  });

  const selectHandleChange = (name, value) => {
    formik.setFieldValue(name, value._id);
    setSelectVehicle(value);
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "rgba(242, 243, 244, 0.5)",
      border: "none",
      outline: "none",
      fontSize: "0.8rem",
    }),
    menuList: (base) => ({
      ...base,
      fontSize: "0.8rem",
    }),
  };
  
  return (
    <div className="support-ticket-create">
      <div className="card custom-card">
        <div className="card-body p-0">
          <form
            id="support-form"
            className="form"
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className="row text-left">
              <div className="col-md-12 col-lg-12 col-xs-12 mb-2">
                <label className="custom-lable mb-2">Vehicle No</label>
                <Select
                  name="vehicleId"
                  value={selectVehicle}
                  options={vehicleData}
                  styles={customStyles}
                  getOptionLabel={(option) => option.vehicleNo}
                  getOptionValue={(option) => option._id}
                  valueType={"string"}
                  onChange={(selectedOption) => {
                    if (selectedOption !== null) {
                      selectHandleChange('vehicleId', selectedOption);
                    } else {
                      selectHandleChange('vehicleId', null);
                    }
                  }}
                  isSearchable={true}
                  isLoading={false}
                  noOptionsMessage="Vehicle not availables."
                  placeholder="Select Vehicle"
                />
                {formik.touched.vehicleId && formik.errors.vehicleId && (
                  <div className="field-error ">{formik.errors.vehicleId}</div>
                )}
              </div>
              <div className="col-md-12 col-lg-12 col-xs-12 mb-2">
                <label className="custom-lable mb-2">Select Reason</label>
                {supportOptions.map((option) => (
                  <div key={option._id} className="form-check custom-radio">
                    <input
                      type="radio"
                      id={`radio-${option._id}`}
                      name="supportType"
                      value={option._id}
                      checked={formik.values.supportType == option._id}
                      onChange={formik.handleChange}
                      className="form-check-input"
                    />
                    <label
                      htmlFor={`radio-${option._id}`}
                      className="form-check-label"
                      style={{fontSize : '12px'}}
                    >
                      {option.name}
                    </label>
                  </div>
                ))}
                {formik.touched.supportType && formik.errors.supportType && (
                  <div className="field-error ">{formik.errors.supportType}</div>
                )}
              </div>
              <div className="col-md-12 col-lg-12 col-xs-12 mb-3">
                <label className="custom-lable mb-2">Additional Comment</label>
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  rows="4"
                  style={{height: '180px', resize: 'none' }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                />
                <div className={formik.touched.message && formik.errors.message && "d-flex justify-content-between"}>
                  {formik.touched.message && formik.errors.message ? (
                    <div className="field-error ">{formik.errors.message}</div>
                  ) : null}
                  <p className="text-danger text-right max-text">MAX - 200</p>
                </div>
              </div>
              <div className="col-md-12 col-lg-12 col-xs-12 text-center">
                <button
                  type="submit"
                  className="btn btn-primary custom-submit-btn"
                  style={{ width: "130px", height: "40px" }}
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SupportTicket;
