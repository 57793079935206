import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import LiveMapContainer from "../../components/LiveMapContainer";
import VerticalProgress from "../../components/VerticalProgress";
import Drawer from "../../components/Drawer";
import { post, get } from "../../components/api";
import { SweetAlert } from "../../helpers/helper";
import SocketIOComponent from "../../components/SocketIOComponent";
import Garges from "../../assets/emergency_services/garages.png";
import Hospitals from "../../assets/emergency_services/hospitals.png";
import Parking from "../../assets/emergency_services/parking.png";
import PetrolPump from "../../assets/emergency_services/petrol_pump.png";
import PoliceStation from "../../assets/emergency_services/police_station.png";
import Restaurant from "../../assets/emergency_services/restaurant.png";
import GasPump from "../../assets/emergency_services/GasPump.png";
import Pin from "../../assets/emergency_services/pin.png";
import hospitalPin from "../../assets/emergency_services/hospital_pin.png";
import fuelPin from "../../assets/emergency_services/fuel_station_pin.png";
import garagePin from "../../assets/emergency_services/garage_pin.png";
import parkingPin from "../../assets/emergency_services/parking_pin.png";
import policePin from "../../assets/emergency_services/police_station_pin.png";
import restaurantPin from "../../assets/emergency_services/restaurant_pin.png";
import Loading from "../../components/Loading";
import { MdOutlineDirections } from "react-icons/md";

const EmergencyServices = [
  {
    value: "garages",
    name: "Garges",
    image: Garges,
  },
  {
    value: "hospital",
    name: "Hospital",
    image: Hospitals,
  },
  {
    value: "dhaba",
    name: "Restaurant",
    image: Restaurant,
  },
  {
    value: "police_station",
    name: "police_station",
    image: PoliceStation,
  },
  {
    value: "fuel_station",
    name: "Fuel Station",
    image: PetrolPump,
  },
  {
    value: "parking",
    name: "Parking",
    image: Parking,
  },
];

const VehicleInfo = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [directions, setDirections] = useState(null);
  const [timeFilter, setTimeFilter] = useState("today");
  const [routeHistoryData, setRouteHistoryData] = useState([]);
  const [stopsData, setStopsData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [socketData, setSocketData] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [fuelStationLoading, setFuelStationLoading] = useState(false);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [nearbyPlaces, setNearbyPlaces] = useState([]);
  const [nearbyPlacesAddress, setNearbyPlacesAddress] = useState([]);
  const [nearByPin, setNearByPin] = useState(Pin);
  const [emergencyServiceType, setEmergencyServiceType] = useState("");
  const [currentVehicleData, setCurrentVehicleData] = useState("");
  const [directionsServiceKey, setDirectionsServiceKey] = useState(0);
  const [routeType, setRouteType] = useState("live");
  const [defaultZoom, setDefaultZoom] = useState(10);
  const [totalRunTime, setTotalTime] = useState("0 kmh (0h:0m)");
  const [stopTime, setStopTime] = useState("0 kmh (0h:0m)");
  const [vehicleData, setVehicleData] = useState([]);
  const [states, setStates] = useState([]);
  const [fuelMarker,setFuelMarker] = useState([]);
  // const [directionOptions, setDirectionOptions] = useState({
  //   travelMode: "DRIVING",
  //   waypoints: [],
  //   optimizeWaypoints: true,
  // });
  // const [directionsResults, setDirectionsResults] = useState([]);
  const { paths } = states;

  const getVehicleInfo = async () => {
    try {
      if (params.id !== undefined && params.id !== null && params.id !== "") {
        setFuelStationLoading(true);
        const getVehicleData = await post("/admin/vehicle-fuel-info", {
          vehicleNo: params.id,
        });
        setVehicleData(getVehicleData.data);
        const progressPercentage = await calculateProgressPercentage(
          getVehicleData.data.currentFuel,
          getVehicleData.data.totalTankCapacity
        );
        const progress = Math.round(progressPercentage);
        setProgress(progress);
        setFuelStationLoading(false);
      } else {
        setVehicleData([]);
        SweetAlert("Unable to fetch the vehicle info. Please try again.", true);
        setFuelStationLoading(false);
      }
    } catch (error) {
      setVehicleData([]);
      SweetAlert("Unable to fetch the vehicle info. Please try again.", true);
      setFuelStationLoading(false);
    }
  };

  const getCurrentVehicleData = async () => {
    try {
      if (state.id !== undefined && state.id !== null && state.id !== "") {
        const getVehicleData = await get("admin/vehicle/" + state.id);
        if (getVehicleData.status == 200) {
          setCurrentVehicleData(getVehicleData.data);
          setDefaultZoom(10);
        } else {
          setCurrentVehicleData("");
          SweetAlert(
            "Unable to fetch the vehicle info. Please try again.",
            true
          );
        }
      } else {
        setCurrentVehicleData("");
        SweetAlert("Unable to fetch the vehicle info. Please try again.", true);
      }
    } catch (error) {
      setVehicleData("");
      SweetAlert("Unable to fetch the vehicle info. Please try again.", true);
    }
  };

  // get vehicle history
  // Function to fetch vehicle history data
  const fetchVehicleHistoryData = async (params, timeFilter, dateRange) => {
    try {
      if (params.id !== undefined && params.id !== null && params.id !== "") {
        let data = {
          vehicleNo: params.id,
          timeFilter: timeFilter,
        };
        if (dateRange !== null) {
          data.startDate = dateRange.startDate;
          data.endDate = dateRange.endDate;
        }
        const getVehicleHistoryData = await post(
          "/admin/get-route-history",
          data
        );
        if (getVehicleHistoryData.status === 200) {
          const stops = [];
          // Use Promise.all() to concurrently process each object
          if (getVehicleHistoryData.data.data.length > 0) {
            getVehicleHistoryData.data.data.forEach((stop, i) => {
              if (stop.status == "stopped") {
                stops.push(stop);
              }
            });
          }
          let locationsArr = getVehicleHistoryData.data.locations;
          const polylinePath = locationsArr.map(item => ({
            lat: item.coordinates[0],
            lng: item.coordinates[1],
            speed: item.speed
          }));
          const locations = polylinePath.reverse();
          return {
            routeHistoryData: getVehicleHistoryData.data.data,
            stops: stops,
            totalRunTime: getVehicleHistoryData.data.totalRunTime,
            stopTime: getVehicleHistoryData.data.stopTime,
            locationData: locations,
            isHistoryLoading: false,
          };
        }
      } else {
        return {
          routeHistoryData: [],
          locationData: [],
          stops: [],
          totalRunTime: "0 kmh (0h:0m)",
          stopTime: "0 kmh (0h:0m)",
          isHistoryLoading: false,
          errorMessage: "Unable to fetch the route history. Please try again.",
        };
      }
    } catch (error) {
      return {
        routeHistoryData: [],
        locationData: [],
        stops: [],
        isHistoryLoading: false,
        errorMessage: "Unable to fetch the route history. Please try again.",
      };
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (state == null) {
          SweetAlert("Unable to fetch the vehicle info.", true);
          setTimeout(() => {
            navigate("/vehicles");
            return false;
          }, 1000);
        }
        setIsHistoryLoading(true);
        // Parallel requests to minimize wait time
        const [vehicleInfo, currentVehicleData, historyData] = await Promise.all([
          getVehicleInfo(),
          getCurrentVehicleData(),
          fetchVehicleHistoryData(params, timeFilter, null),
        ]);
        console.log("historyData.locationData", historyData.locationData);
        setRouteHistoryData(historyData.routeHistoryData);
        setLocationData(historyData.locationData);
        setStopsData(historyData.stops);
        setIsHistoryLoading(historyData.isHistoryLoading);
        setTotalTime(historyData?.totalRunTime);
        setStopTime(historyData?.stopTime);
      } catch (error) {
        SweetAlert(error.message, true);
      }
    };
    fetchData();
  }, [state]);

  // direction api
  // const fetchDirections = (options) => {
  //   return new Promise((resolve, reject) => {
  //     const directionsService = new window.google.maps.DirectionsService();
  //     directionsService.route(options, (result, status) => {
  //       if (status === 'OK' && result) {
  //         resolve(result);
  //       } else {
  //         console.error(`Error fetching directions: ${status}`);
  //         reject(status);
  //       }
  //     });
  //   });
  // };
  // useEffect(() => {
  //   if (currentVehicleData[0]?.todayKilometers > 2 && locationData.length > 0) {
  //     const waypointsChunks = chunkArray(locationData, 23);
  //     const allDirectionsResults = [];
  //     const fetchDirectionsForChunks = async () => {  
  //       for (const chunk of waypointsChunks) {
  //         const options = {
  //           origin: {
  //             lat: chunk[0].location.lat,
  //             lng: chunk[0].location.lng,
  //           },
  //           destination: {
  //             lat: chunk[chunk.length - 1].location.lat,
  //             lng: chunk[chunk.length - 1].location.lng,
  //           },
  //           waypoints: chunk.slice(1, chunk.length - 1).map(loc => ({
  //             location: loc.location,
  //           })),
  //           travelMode: "DRIVING",
  //           optimizeWaypoints: true,
  //         };
  //         try {
  //           const directionsResult = await fetchDirections(options);
  //           allDirectionsResults.push(directionsResult);
  //         } catch (error) {
  //           console.error(`Failed to fetch directions for chunk: ${error}`);
  //         }
  //         // Delay between requests to avoid hitting API limits
  //         await new Promise(resolve => setTimeout(resolve, 1));
  //       }
  //       setDirectionsResults(allDirectionsResults);
  //     };
  //     fetchDirectionsForChunks();
  //   }
  // }, [locationData]);

  // const chunkArray = (array, chunkSize) => {
  //   const chunks = [];
  //   for (let i = 0; i < array.length; i += chunkSize) {
  //     chunks.push(array.slice(i, i + chunkSize));
  //   }
  //   return chunks;
  // };
  // const memoizedDirectionOptions = useMemo(() => directionOptions, [directionOptions]);
  // const memoizedDirectionsServiceKey = useMemo(() => directionsServiceKey, [directionsServiceKey]);
  // Callback function for DirectionsService
  // const directionsCallback = useCallback((result, status) => {
  //   if (status === "OK" && result) {
  //     console.log("result", result);
  //     // setDirectionsResults(prevResults => [...prevResults, result]);
  //   } else {
  //     console.error(`Error fetching directions: ${status}`);
  //   }
  // }, [directionsServiceKey]);

  // calculate fuel level
  const calculateProgressPercentage = async (
    currentFuelLevel,
    totalCapacity
  ) => {
    if (totalCapacity <= 0) {
      return 0; // Prevent division by zero
    }
    return (parseFloat(currentFuelLevel) / parseFloat(totalCapacity)) * 100;
  };

  const selectEmergencyService = async (value) => {
    if (routeType == "route-history") {
      setIsHistoryLoading(true);
      const data = await fetchVehicleHistoryData(params, "today", null);
      setRouteHistoryData(data.routeHistoryData);
      setLocationData(data.locationData);
      setStopsData(data.stops);
      setIsHistoryLoading(data.isHistoryLoading);
      setDirections(null);
      setDirectionsServiceKey(prevKey => {
        if (prevKey !== directionsServiceKey) {
          return directionsServiceKey;
        }
        return prevKey;
      });
      setTimeFilter("today");
      setRouteType("live");
      setTotalTime(data?.totalRunTime);
      setStopTime(data?.stopTime);
      const currentLoc = locationData[locationData.length - 1];
      const response = await post("/admin/get-nearby-places", {
        place: value,
        coordinates: [currentLoc.lat, currentLoc.lng],
      });
      if (response.status == 200) {
        setEmergencyServiceType(value);
        setNearbyPlacesAddress(response.data);
        const placesData = [];
        response.data.forEach((data, i) => {
          if (value == "garages") {
            data.pin = garagePin;
          }
          if (value == "hospital") {
            data.pin = hospitalPin;
          }
          if (value == "dhaba") {
            data.pin = restaurantPin;
          }
          if (value == "fuel_station") {
            data.pin = fuelPin;
          }
          if (value == "parking") {
            data.pin = parkingPin;
          }
          if (value == "police_station") {
            data.pin = policePin;
          }
          placesData.push(data);
          setNearByPin(data.pin);
        });
        setNearbyPlaces(placesData);
        setDefaultZoom(11);
      }
    } else {
      const currentLoc = locationData[locationData.length - 1];
      const response = await post("/admin/get-nearby-places", {
        place: value,
        coordinates: [currentLoc.lat, currentLoc.lng],
      });
      if (response.status == 200) {
        setEmergencyServiceType(value);
        setNearbyPlacesAddress(response.data);
        const placesData = [];
        response.data.forEach((data, i) => {
          if (value == "garages") {
            data.pin = garagePin;
          }
          if (value == "hospital") {
            data.pin = hospitalPin;
          }
          if (value == "dhaba") {
            data.pin = restaurantPin;
          }
          if (value == "fuel_station") {
            data.pin = fuelPin;
          }
          if (value == "parking") {
            data.pin = parkingPin;
          }
          if (value == "police_station") {
            data.pin = policePin;
          }
          placesData.push(data);
          setNearByPin(data.pin);
        });
        setNearbyPlaces(placesData);
        setDefaultZoom(12);
      }
    }
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
    setRouteType("route-history");
    if (drawerOpen) {
      setTimeFilter("today");
    }
  };

  const selectRouteHistoryByDateRange = async (type) => {
    if (type !== "range") {
      setIsHistoryLoading(true);
      const data = await fetchVehicleHistoryData(params, type, null);
      setRouteHistoryData(data.routeHistoryData);
      setLocationData(data.locationData);
      setStopsData(data.stops);
      setIsHistoryLoading(data.isHistoryLoading);
      setDirections(null);
      setDirectionsServiceKey(prevKey => {
        if (prevKey !== directionsServiceKey) {
          return directionsServiceKey;
        }
        return prevKey;
      });
      setTimeFilter(type);
      setTotalTime(data?.totalRunTime);
      setStopTime(data?.stopTime);
    }
  };
  const historyDataCustomRange = async (type, startDate, endDate) => {
    setIsHistoryLoading(true);
    const data = await fetchVehicleHistoryData(params, "range", {
      startDate,
      endDate,
    });
    setRouteHistoryData(data.routeHistoryData);
    setLocationData(data.locationData);
    setStopsData(data.stops);
    setIsHistoryLoading(data.isHistoryLoading);
    setDirections(null);
    setDirectionsServiceKey(prevKey => {
      if (prevKey !== directionsServiceKey) {
        return directionsServiceKey;
      }
      return prevKey;
    });
    setTimeFilter(type);
    setTotalTime(data?.totalRunTime);
    setStopTime(data?.stopTime);
  };

  const changeRouteType = async () => {
    setIsHistoryLoading(true);
    const data = await fetchVehicleHistoryData(params, "today", null);
    setRouteHistoryData(data.routeHistoryData);
    setLocationData(data.locationData);
    setStopsData(data.stops);
    setIsHistoryLoading(data.isHistoryLoading);
    setDirections(null);
    setDirectionsServiceKey(prevKey => {
      if (prevKey !== directionsServiceKey) {
        return directionsServiceKey;
      }
      return prevKey;
    });
    setTimeFilter("today");
    setRouteType("live");
    setTotalTime(data?.totalRunTime);
    setStopTime(data?.stopTime);
    setNearbyPlaces([]);
    setNearbyPlacesAddress([]);
    setEmergencyServiceType("");
    setFuelMarker([])
  };

  // Received data from socket
  let socketDataBuffer = [];
  function handleEvent(data) {
    if (data?.vehicleNo === params.id && timeFilter === 'today') {
      if (data?.speed > 0) {
        let newData = {
          lat: data?.lat,
          lng: data?.lng,
          speed: data?.speed,
        };
        socketDataBuffer.push(newData);
        let tempVehicleData = currentVehicleData;
        tempVehicleData[0] = { ...tempVehicleData[0], tripVehicleStatus: data.tripVehicleStatus };
        if (socketDataBuffer.length > 1) {
          setCurrentVehicleData(tempVehicleData);
          setSocketData((prevData) => [...prevData, ...socketDataBuffer]);
          socketDataBuffer = []; // Clear the buffer after processing
        }
      }
    }
  }

  const openGoogleMaps = (lat, lng) => {
    const destinationLat = lat;
    const destinationLng = lng;
    const originLat = locationData[locationData.length - 1].lat;
    const originLng = locationData[locationData.length - 1].lng;
    const url = `https://www.google.com/maps/dir/?api=1&origin=${originLat},${originLng}&destination=${destinationLat},${destinationLng}`;
    window.open(url, "_blank");
  }
  const openGooglePosition = (lat,lng) => {
    const url = `https://www.google.com/maps?q=${lat},${lng}&z=15`; // `z` parameter controls the zoom level
  window.open(url, "_blank");
  }


  return (
      <>
      <div className="row p-3">
        {/* <div className="col-md-7 col-lg-8 col-xs-12"> */}
        <div className={vehicleData.isFuel ? 'col-md-7 col-lg-8 col-xs-12' : 'col-md-12 col-lg-12 col-xs-12'}>
          <div className="row">
            <div className="col-md-12 col-lg-12 col-xs-12">
              <div className="card custom-card">
                {locationData.length <= 0 && isHistoryLoading ? (
                  <div className="h-350 d-flex justify-content-center align-items-center"><Loading /></div>
                ) : locationData.length <= 0 && !isHistoryLoading && routeType !== 'live' ? <div className="h-340 d-flex justify-content-center align-items-center">
                  <p className="mb-0">No History Found</p>
                </div> : (
                  <div className="map-wrapper">
                    <LiveMapContainer
                      locationData={locationData}
                      currentVehiclePosition={routeType === 'live' && locationData.length <= 0 ? currentVehicleData[0]?.currentCoordinates : locationData[locationData?.length - 1]}
                      socketData={socketData}
                      nearbyPlaces={nearbyPlaces}
                      emergencyServiceType={emergencyServiceType}
                      fuelMarker={fuelMarker}
                      vehicleId={state.id}
                      currentVehicleData={currentVehicleData[0]}
                      timeFilter={timeFilter}
                      stopsData={stopsData}
                      drawerOpen={drawerOpen}
                      routeType={routeType}
                      defaultZoom={defaultZoom}
                      maxZoom={10}
                      paths = {paths}
                      point = {paths => setStates({paths})}
                      networkStrength={vehicleData.networkStrength}
                      // directionsCallback={directionsCallback}
                      // directionOptions={memoizedDirectionOptions}
                      // directions={directions}
                      // directionsServiceKey={memoizedDirectionsServiceKey}
                      // setDirectionsServiceKey={setDirectionsServiceKey}
                      // setDirections={setDirections}
                      // directionsResults={directionsResults}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-12 col-lg-12 mt-3 d-md-flex d-lg-flex justify-content-between align-items-center">
              <h6 className="text-left nearby-text d-none d-md-block d-lg-block">Explore Nearby Services</h6>
              <div className="map-btns">
                <Button
                  variant={
                    routeType == "live"
                      ? "route-active-btn mr-10"
                      : "outline-primary primary-btn mr-10"
                  }
                  onClick={() => changeRouteType()}
                >
                  Live
                </Button>
                <Button
                  variant={
                    routeType == "route-history"
                      ? "route-active-btn"
                      : "outline-primary primary-btn"
                  }
                  onClick={toggleDrawer}
                >
                  Route history
                </Button>
                <Button
                  variant="outline-primary primary-btn ml-10"
                  onClick={() => navigate(`/playback/${params?.id}`)}
                >
                  Playback
                </Button>
              </div>
              <h6 className="text-left nearby-text d-md-none d-lg-none mt-3 mb-0">Explore Nearby Services</h6>
            </div>
            <div className="col-md-12 col-lg-7 col-xs-12 d-flex justify-content-between align-items-center mt-3">
              {EmergencyServices.length > 0 &&
                EmergencyServices.map((service, i) => (
                  <div
                    className="emergency-services"
                    key={`Emergenct-${i}`}
                    onClick={() => selectEmergencyService(service.value)}
                  >
                    <img
                      src={service.image}
                      alt={service.name}
                      width={"30px"}
                      height={"30px"}
                    />
                  </div>
                ))}
            </div>
            {nearbyPlacesAddress.length > 0 && <div className="col-md-12 col-lg-12 col-xs-12 d-flex justify-content-between align-items-center mt-3">
            <div className="card custom-card mt-4 w-100">
             
            <div>
              <div className="d-flex align-items-center p-2">
                {/* <img src={nearByPin} alt="" width={"35px"} height={"35px"} /> */}
                {/* <MdOutlineDirections size={"25px"}/> */}
                <h6 className="text-left mb-2 mt-2 ml-5">{`Near By ${emergencyServiceType}`}</h6>
              </div>
              <div className="fuel-station-scroll">
                <div className="fuel-station-parent">
                  {/* {fuelStationLoading && <Loading />} */}
                  {nearbyPlacesAddress !== undefined &&
                    nearbyPlacesAddress.length > 0 &&
                    nearbyPlacesAddress.map((address, i) => {
                      return (
                        <div className="mt-3"
                            key={`petrol-pump-${i}`}
                            >
                          <div className="d-flex justify-content-start align-items-center">
                            <MdOutlineDirections size={"25px"}/>
                            <div className="text-left ml-15">
                              <p className="mb-0 fuel-title fw-bolder map-item"
                                onClick={() => openGoogleMaps(address.coordinates[0], address.coordinates[1])}>
                                {address.address}
                              </p>
                              <p className="mb-0 fuel-title text-left">
                                {address.distance/1000} KM
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
            </div>}
          </div>
        </div>
        {vehicleData.isFuel ? (<div className="col-md-5 col-lg-4 col-xs-12 mt-4 mt-md-0 mt-lg-0">
          <div className="card custom-card">
            <div className="p-3">
              <h6 className="text-left mb-2">Fuel Information</h6>
              {fuelStationLoading ? (
                <Loading />
              ) : (
                <VerticalProgress
                  progress={progress}
                  vehicleData={vehicleData}
                />
              )}
            </div>
          </div>
          <div className="card custom-card mt-4">
            <div>
              <div className="d-flex align-items-center p-2">
                <img src={GasPump} alt="" width={"35px"} height={"35px"} />
                <h6 className="text-left mb-0 ml-5">Last Fuel Stations</h6>
              </div>
              <div className="fuel-station-scroll">
                <div className="fuel-station-parent">
                  {fuelStationLoading && <Loading />}
                  {vehicleData !== undefined &&
                    vehicleData.fuelStations !== undefined &&
                    vehicleData.fuelStations.length > 0 &&
                    vehicleData.fuelStations.map((fuelPump, i) => {
                      return (
                        <div className="mt-3" key={`petrol-pump-${i}`}>
                          <div className="d-flex justify-content-start align-items-center">
                            <img
                              src={Pin}
                              alt=""
                              width={"20px"}
                              height={"20px"}
                              style={{cursor : "pointer"}}
                              onClick={() => openGooglePosition(fuelPump.coordinates[0], fuelPump.coordinates[1])}
                            />
                            <div className="text-left ml-15">
                              <p className="mb-0 fuel-title fw-bolder" onClick={() => setFuelMarker(fuelPump)}>
                                {fuelPump.name}
                              </p>
                              <p className="mb-0 fuel-title text-left">
                                {fuelPump.address}
                              </p>
                              <p className="mb-0 fuel-title ">
                                {fuelPump.timeStamp}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {vehicleData !== undefined &&
                    vehicleData.fuelStations !== undefined &&
                    vehicleData.fuelStations.length <= 0 &&
                    !fuelStationLoading && (
                      <div>
                        <p>No fuel station found</p>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>): <></>}
      </div>
      {state && (
        <SocketIOComponent
          endpoint="http://tsocket.fleetstakes.com:9004"
          event="load_vehicles"
          onEvent={handleEvent}
        />
      )}
      {drawerOpen && (
        <Drawer
          show={drawerOpen}
          closeDrawer={toggleDrawer}
          data={routeHistoryData}
          timeFilter={timeFilter}
          historyDataFilter={selectRouteHistoryByDateRange}
          isLoading={isHistoryLoading}
          historyDataCustomRange={historyDataCustomRange}
          totalRunTime={totalRunTime}
          stopTime={stopTime}
        />
      )}
    </>
  );
};

export default VehicleInfo;
