import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from 'moment';
import { Button, Card } from "react-bootstrap";
import { GoogleMap, Marker, Polyline, InfoWindow } from "@react-google-maps/api";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import ReactSlider from "react-slider";
import { FaTruckMoving } from "react-icons/fa6";
import { FaPlayCircle, FaPauseCircle, FaMapMarkerAlt } from "react-icons/fa";
import { BsFuelPump } from "react-icons/bs";
import { IoSpeedometerOutline } from "react-icons/io5";
import { MdOutlineWatchLater } from "react-icons/md";
import Dropdown from "react-bootstrap/Dropdown";
import SVG from "react-inlinesvg";
import { SweetAlert } from "../../helpers/helper";
import Loading from "../../components/Loading";
import { post } from "../../components/api";
import ProgressBar from "../../components/ProgressBar";
import CustomTooltip  from "../../components/Tooltip";
import View from "../../assets/emergency_services/view.svg";
import truck_slider from "../../assets/images/truck_slider.svg";
import RunningVehicle from "../../assets/images/running_vehicle.png";
import stopIcon from "../../assets/vehicles/stopIcon.svg";
import idleIcon from "../../assets/vehicles/idleicon.svg";
import fuelFillIcon from "../../assets/vehicles/fuelfillicon.svg";

const mapContainerStyle = {
  width: "100%",
  height: "500px",
};

const VehiclePlayRoute = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [map, setMap] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [completedPath, setCompletedPath] = useState([]);
  const [pendingPath, setPendingPath] = useState([]);
  const [openInfoWindow, setOpenInfoWindow] = useState(true);
  const [currentPointIndex, setCurrentPointIndex] = useState(0);
  const [currentPosition, setCurrentPosition] = useState({
    lat: 22.9889, lng: 72.498297
  });
  const [animationInterval, setAnimationInterval] = useState(null);
  const [mapType, setMapType] = useState('roadmap');
  const [speedMultiplier, setSpeedMultiplier] = useState(1);
  const [timeFilter, setTimeFilter] = useState({
    start: moment().startOf('day').toISOString(),
    end: moment().toISOString()
  });
  const [playRoute, setPlayRoute] = useState(true);
  const [zoomLevel, setZoomLevel] = useState(14);
  const vehicleNo = params?.id;
  const [path, setPaths] = useState([]);
  const [vehicleHistory, setVehicleHistory] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const today = moment().endOf('day');
  const now = moment();
  const maxSpan = {
    days: 30
  };
  const initialSettings = {
    startDate: moment().startOf('day').toDate(),
    endDate: moment().toDate(),
    timePicker: true,
    timePicker24Hour: false,
    maxDate: today.toDate(),
    maxTime: now.format('HH:mm'),
    maxSpan: maxSpan,
    locale: {
      format: 'DD MMM YYYY', // 12-hour format
    },
    opens: 'left',
    ranges: {
      'Today': [moment().startOf('day'), moment()],
      'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
      'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment()],
      'Last 15 Days': [moment().subtract(14, 'days').startOf('day'), moment()]
    }
  }

  const speedToZoom = {
    '1': 18,
    '2': 16,
    '4': 14,
    '8': 12,
    '16': 10,
    '32': 8,
  };

  const getRouteData = async (timeFilter) => {
    setIsLoading(true);
    try {
      let jsonData = {
        vehicleNo: vehicleNo,
        timeFilter: timeFilter
      }
      const routeData = await post("/admin/vehicle-play-route", jsonData);
      if (routeData.status === 200) {
          const updatedData = routeData.data.data.map((stop) => {
            const [stopLat, stopLng] = stop.coordinates;
            const matchingLocationIndex = routeData.data.locations.findIndex((location) => {
              return (
                stopLat === location.lat && // Check latitude
                stopLng === location.lng     // Check longitude
              );
            });
            if (matchingLocationIndex !== -1) {
              return { ...stop, position: matchingLocationIndex }; // Add position
            }
            return stop;
        });
        setPendingPath([]);
        setCompletedPath([]);
        setPaths(routeData?.data?.locations);
        setVehicleHistory(updatedData);
        setIsLoading(false);
      } else {
        setPaths([]);
        setIsLoading(false);
        SweetAlert(routeData.message, true);
        setTimeout(() => {
          navigate("/vehicles");
        }, 2000);
      }
    } catch (err) {
      console.log("err", err.message);
    }
  };

  useEffect(() => {
    getRouteData(timeFilter);
  },[timeFilter]);

  useEffect(() => {
    // Only start the animation if we have a valid next point
    if (playRoute && currentPointIndex < path.length - 1) {
      const nextPoint = path[currentPointIndex + 1];
      // Call the interpolate function to animate the movement
      interpolateMovement(path[currentPointIndex], nextPoint);
      console.log("currentPointIndex", currentPointIndex);
      // Update completed path
      setPendingPath(path.slice(currentPointIndex))
      setCompletedPath((prev) => {
        const newPath = [...prev];
        const currentPath = path[currentPointIndex];
        if (!newPath.includes(currentPath)) {
          // If moving forward, append the current path to the new array
          newPath.push(currentPath);
        } else {
          // If moving backward, remove the subsequent elements beyond the current index
          const currentIndexInPath = newPath.indexOf(currentPath);
          newPath.splice(currentIndexInPath + 1); // Keep the current element and remove the rest
        }
        return newPath;
      });

      // Set an interval to update the index after 5 seconds
      const interval = setTimeout(() => {
        setCurrentPointIndex((prevIndex) => {
          if (prevIndex < path.length - 1) {
            return prevIndex + 1;
          }
          return prevIndex;
        });
      }, 4000);
      return () => clearTimeout(interval);
    }
  }, [currentPointIndex, path, playRoute]);

   // Interpolation function for smooth movement
   const interpolateMovement = async (startPoint, endPoint) => {
    const baseDuration = 2000; // Duration of movement
    const adjustedDuration = baseDuration;
    const startLat = startPoint.lat;
    const startLng = startPoint.lng;
    const endLat = endPoint.lat;
    const endLng = endPoint.lng;
    const latDiff = endLat - startLat;
    const lngDiff = endLng - startLng;
    const startTime = performance.now();
    const animate = (currentTime) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / adjustedDuration, 1); // Ensure progress is capped at 1
      const currentLat = startLat + latDiff * progress;
      const currentLng = startLng + lngDiff * progress;
      setCurrentPosition({ lat: currentLat, lng: currentLng });
      if (progress < 1) {
        requestAnimationFrame(animate);
      } 
    };
    requestAnimationFrame(animate);
  };

  const handleSliderChange = (value) => {
    console.log("value====", value);
    setCurrentPointIndex(value);
    setCurrentPosition({
      lat: path[value]?.lat,
      lng: path[value]?.lng
    });
    // setPendingPath(path.slice(value));
    // setCompletedPath((prev) => [...prev, path[value]]);
    if (animationInterval) {
      clearTimeout(animationInterval);
      setAnimationInterval(null);
    }
    // setPlayRoute(false);
  };

  const handleSelect = (eventKey) => {
    const selectedSpeed = parseInt(eventKey.replace("x", ""), 10);
    const newZoom = speedToZoom[selectedSpeed];
    setSpeedMultiplier(selectedSpeed);
    setZoomLevel(newZoom);
    if (map) {
      map.setZoom(newZoom);
    }
    if (selectedSpeed == 32) {
      const bounds = new window.google.maps.LatLngBounds();
      path.forEach(point => bounds.extend({ lat: point.lat, lng: point.lng }));
      vehicleHistory.forEach(stop => bounds.extend({ lat: stop.coordinates[0], lng: stop.coordinates[1] }));
      map.fitBounds(bounds); // Fit all points on the map
    }
  };

  const toggleMapType = (type) => {
    if (type == 'view-type') {
      const newMapType = mapType === 'roadmap' ? 'satellite' : 'roadmap';
      setMapType(newMapType); // Update mapType state
    }
  };

  const handleMarkerClick = () => {
    console.log("openInfoWindow", openInfoWindow);
    setOpenInfoWindow(!openInfoWindow);
  }

  const mapOptions = {
    mapTypeId: mapType,
    zoomControl: false,
    streetViewControl: false, // Disable street view control
    fullscreenControl: false, // Disable fullscreen control
    mapTypeControl: false, // Disable map type control
    trafficControlOptions: {
      mapTypeIds: ['roadmap', 'satellite'],
      style: window.google.maps.TrafficStyle
    }
  }

  // change date range
  const handleApply = (event, picker) => {
    const selectedStart = picker.startDate;
    const selectedEnd = picker.endDate;
    const startDateTime = selectedStart.toISOString();
    const endDateTime = selectedEnd.toISOString();
    setTimeFilter({
      start: startDateTime,
      end: endDateTime
    });
  }

  // Handler for play/pause route
  const togglePlayPause = () => {
    setPlayRoute((prevPlayRoute) => !prevPlayRoute);
    if (playRoute) {
      if (animationInterval) {
        clearTimeout(animationInterval);
        setAnimationInterval(null); // Reset interval ID
      }
    }
  };
  const center = { lat: 22.9889, lng: 72.498297 }; // Center of the map
  // console.log("==============", completedPath);
  return (
    <div className="p-3">
      <div className="d-flex justify-content-between">
        <Button
          variant="route-active-btn"
          onClick={() => navigate('/vehicles')}
        >
          Back to Vehicles
        </Button>
        <DateRangePicker
          className="custom-date-range"
          initialSettings={initialSettings}
          onApply={handleApply}
          style = {{width : "200px"}}
          >
            <input type="text" className="form-control" style={{width : "200px"}}/>
        </DateRangePicker>
      </div>
      <Card className="mt-2">
        <Card.Body>
          {isLoading ? <Loading /> : 
            <>
              <div className="google-map-parent">
              <div className="google-map-parent">
                <GoogleMap
                  onLoad={setMap}
                  zoom={zoomLevel}
                  center={currentPosition ? currentPosition : center}
                  mapContainerStyle={mapContainerStyle}
                  options={mapOptions}
                >
                  <Polyline
                    path={pendingPath} // Pending path for visualization
                    options={{
                      strokeColor: '#7F92A4',
                      strokeWeight: 5
                    }}
                  />
                  <Polyline
                    path={completedPath} // Completed path for the polyline
                    options={{
                      strokeColor: '#2A7C76', // Color for the completed path
                      strokeWeight: 5
                    }}
                  />
                  <Marker 
                    position={currentPosition} 
                    options={{
                      optimized: true,
                    }}
                    icon={{
                      url: RunningVehicle,
                      scaledSize: new window.google.maps.Size(40, 40),
                    }}
                    onClick={() => handleMarkerClick()}
                  >
                    {openInfoWindow && <InfoWindow onCloseClick={() => handleMarkerClick()}>
                      <div style={{ color: '#fff', width: '400px', display: 'flex', flexDirection: 'row', textAlign: 'left', padding: '5px', alignItems: "baseline"}} className="infowindow-play-route">
                        <div className="p-2">
                          <div className="d-flex mb-1">
                            <FaTruckMoving size="20px" color="#ffffff" />
                            <h6 className="ml-2 font-14">{path[currentPointIndex]?.vehicleNo}</h6>
                          </div>
                          <div className="d-flex align-items-start mt-1 mb-1">
                            <div style={{width: '15px'}}><FaMapMarkerAlt size="20px" color="#ffffff" /></div>
                            <p className="ml-3 mb-2">{path[currentPointIndex]?.address}</p>
                          </div>
                          <div className="mt-1 mb-2 w-230">
                            <div className="d-flex align-items-center">
                              <div style={{width:'15px'}}><BsFuelPump size="20px" color="#ffffff" /></div>
                              <p className="ml-3 mb-0">Fuel: <b className="font-600">{path[currentPointIndex]?.fuel} L /  {path[currentPointIndex]?.maxTankCapacity} L</b></p>
                            </div>
                            <div className="mt-2 mb-2 w-200">
                              <ProgressBar value={path[currentPointIndex]?.fuel} maxValue={path[currentPointIndex]?.maxTankCapacity} />
                            </div>
                          </div>
                          <div className="d-flex mt-1 mb-2 justify-content-between">
                            <div className="d-flex align-items-center">
                              <div style={{width: '15px'}}><IoSpeedometerOutline size="20px" color="#ffffff" /></div>
                              <p className="ml-3 mb-0">{path[currentPointIndex]?.speed} KMPH</p>
                            </div>
                            <div className="d-flex align-items-center">
                                <div style={{width: '15px'}}><MdOutlineWatchLater size="20px" color="#ffffff" /></div>
                                <p className="ml-3 mb-0">{path[currentPointIndex]?.timeStamp}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </InfoWindow>}
                  </Marker>
                  {vehicleHistory.length > 0 && vehicleHistory.map((stop, index) => (
                     <Marker
                      key={index}
                      position={{
                        lat: stop.coordinates[0],
                        lng: stop.coordinates[1],
                      }}
                      title={vehicleHistory.length - index}
                      icon={{
                        url: stop.status == 'idle' ? idleIcon : stopIcon,
                        scaledSize: { width: 36, height: 36 },
                        scale: 7,
                      }}
                    ></Marker>
                  ))}
                </GoogleMap>
                </div>
              </div>
              <div className='map-view-icon'>
                <div className={mapType == 'roadmap' ? `image-container mt-3 mb-2` : `image-container-active mt-3 mb-2`} onClick={() => toggleMapType('view-type')}>
                  <SVG src={View} width={'20px'} />
                </div>
              </div>
              <div className="slider-main">
                <div className="slider-parent">
                  <div onClick={togglePlayPause} style={{ cursor: 'pointer' }}>
                    {playRoute ? (
                      <FaPauseCircle style={{ color: "#00a3fe", fontSize: "38px", margin: "10px" }} />
                    ) : (
                      <FaPlayCircle style={{ color: "#00a3fe", fontSize: "38px", margin: "10px" }} />
                    )}
                  </div>
                  <Dropdown onSelect={handleSelect}>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      {`${speedMultiplier}x`}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="speed-dropdown">
                      {Array.from({ length: 6 }, (_, i) => (
                        <Dropdown.Item key={i} eventKey={`${Math.pow(2, i)}x`}>
                          {Math.pow(2, i)}x
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  {path && path.length > 0 && <ReactSlider
                    className="horizontal-slider"
                    ariaLabelledby="slider-label"
                    min={0}
                    max={path.length - 1}
                    value={currentPointIndex}
                    onChange={handleSliderChange}
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    renderThumb={(props, state) => {
                      const currentIndex = state.value;
                      const { timeStamp } = path[currentIndex];
                      return (
                        <>
                          <div {...props} 
                            className="example-track"
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                          >
                            <img
                              src={truck_slider}
                              alt="Vehicle"
                              className="thumb-image"
                              data-tooltip-id={`tooltip-${currentIndex}`}
                              data-tooltip-content={timeStamp}
                            />
                          </div>
                          <CustomTooltip
                            key={`tooltip-${currentIndex}`} // Unique key for each tooltip
                            id={`tooltip-${currentIndex}`}
                            content={timeStamp} // Dynamic content from path
                            className='dark-tooltip'
                            visible={isHovered}
                          />
                          {vehicleHistory.map((stop, index) => {
                            return (
                              <div
                                key={index}
                                className="icon-container"
                                style={{
                                  position: 'absolute',
                                  left: `${(stop.position / (path.length - 1)) * 100}%`,
                                  transform: 'translateX(-50%)',
                                  top: 1
                                }}
                              >
                                <SVG src={stop.status == 'idle' ? idleIcon : stopIcon} width={28} height={28}/>
                              </div>
                            );
                          })}
                        </>
                      );
                    }}
                  />}
                </div>
              </div>
            </>
          }
        </Card.Body>
      </Card>
    </div>
  );
};

export default VehiclePlayRoute;
