import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import { useNavigate, useParams } from 'react-router-dom';
import { AiOutlineUpload } from "react-icons/ai";
import { get, post } from "../../components/api";
import { ReactSelect } from "../../components/ReactSelect";
import { Lightbox } from "react-modal-image";
import { SweetAlert } from '../../helpers/helper';
import ProfileImg from "../../assets/images/profile.png";
import "./index.css";
import moment from "moment";
import Translate from "../../components/Translate";

const profileSchema = Yup.object().shape({
  ownerName: Yup.string().required("Owner name is required"),
  mobileNo: Yup.string().required("Mobile number is required"),
  dob: Yup.date().nullable().required("Date of birth is required"),
  address: Yup.string().required("Address is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  gender: Yup.string().required("Gender is required"),
  // adharPhoto: Yup.string().required("Aadhar photo is required"),
  // panPhoto: Yup.string().required("PAN photo is required"),
  // photo: Yup.string().required("Photo is required"),
  name: Yup.string().required("Company name is required"),
  companyAddress: Yup.string().required("Company address is required"),
  companyWeb: Yup.string().url('Invalid URL format')
});

const ProfileEdit = () => {
  const params = useParams();
  let navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [states, setState] = useState([]);
  const [cities, setCities] = useState([]);
  const [genderValue, setGenderValue] = useState('');
  const [stateValue, setStateValue] = useState('');
  const [cityValue, setCityValue] = useState('');
  const [showAdharCard, setShowAdharCard] = useState(false);
  const [showPanCard, setShowPanCard] = useState(false);
  const [profileData, setProfileData] = useState({
    ownerName: "",
    mobileNo: "",
    dob: "",
    address: "",
    state: "",
    city: "",
    email: "",
    gender: "",
    // images
    adharPhotoFile: "",
    adharPhoto: "",
    panPhotoFile: "",
    panPhoto: "",
    photoFile: "",
    photo: "",
    // comapny
    name: "",
    companyAddress: "",
    companyWeb: "",
  });

  // Gender Options
  const options = [
    { _id: "Male", name: "Male" },
    { _id: "Female", name: "Female" },
  ];

  useEffect(() => {
    const fetchProfileData = async () => {
        try {
          const stateList = await get("/states");
          const response = await get("/admin/profile/edit/" + params.id);
          if (response.status == 200){
            const citiesList = await get("/cities/" + response.data.state);
            const foundGender = options.find(item => item._id === response?.data?.gender);
            const foundState = stateList?.data.find(item => item._id === response.data.state);
            const foundCity = citiesList?.data.find(item => item._id === response.data.city);
            let jsonData = {
              ownerName: response.data.ownerName,
              mobileNo: response.data.mobileNo,
              dob: response.data.dob,
              address: response.data.address,
              state: foundState._id,
              city:  foundCity._id,
              email: response.data.email,
              adharPhotoFile: response.data.adharPhoto,
              panPhotoFile: response.data.panPhoto,
              photoFile: response.data.photo,
              name: response.data.name ? response.data.name: '',
              companyAddress: response.data.companyAddress ? response.data.companyAddress: '',
              companyWeb: response.data.companyWeb ? response.data.companyWeb : '',
            };

            if (foundGender) {
              jsonData.gender = foundGender?._id;
            }

            setStateValue(foundState);
            setCityValue(foundCity);
            setGenderValue(foundGender);
            setState(stateList.data);
            setCities(citiesList.data);
            setProfileData(jsonData);
          } else {
            setProfileData("");
            navigate('/dashboard');
          }
        } catch (error) {
          console.log(error);
        }
    };
    fetchProfileData();
  }, [])

  const formik = useFormik({
    initialValues: profileData,
    validationSchema: profileSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const formData = new FormData();
      formData.append('ownerName', values.ownerName);
      formData.append('email', values.email);
      formData.append('gender', values.gender);
      formData.append('dob', moment(values.dob).format('DD/MM/YYYY'));
      formData.append('mobileNo', values.mobileNo);
      formData.append('address', values.address);
      formData.append('state', values.state);
      formData.append('city', values.city);
      // company
      formData.append('name', values.name);
      formData.append('companyAddress', values.companyAddress);

      if (values.companyWeb !== '') {
        formData.append('companyWeb', values.companyWeb);
      }
      
      // photos
      if (values.photo !== undefined && values.photo !== null && values.photo !== '') {
        formData.append('photo', values.photo);
      }
      if (values.adharPhoto !== undefined && values.adharPhoto !== null && values.adharPhoto !== '') {
        formData.append('adharPhoto', values.adharPhoto);
      }
      if (values.panPhoto !== undefined && values.panPhoto !== null && values.panPhoto !== '') {
        formData.append('panPhoto', values.panPhoto);
      }
      const updateData = await post("/admin/profile/edit/" + params.id, formData);
      if(updateData.status === 200) {
        SweetAlert(updateData.message, false);
        setSubmitting(false);
        setTimeout(() => {
          navigate('/profile/'+ params.id);
        }, 2000);
      } else {
        SweetAlert(updateData.message, true);
        setSubmitting(false);
      }
    },
  });

  // upload image
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event, name) => {
    const file = event.target.files[0];
     if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (name == 'photo') {
          formik.setValues({
            ...formik.values,
            photo: file,
            photoFile: e.target.result
          });
        }
        if (name == "adharPhoto") {
          formik.setValues({
            ...formik.values,
            adharPhoto: file,
            adharPhotoFile: e.target.result
          });
        }
        if (name == "panPhoto") {
          formik.setValues({
            ...formik.values,
            panPhoto: file,
            panPhotoFile: e.target.result
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const selectHandleChange = (name, value) => {
    if (name == 'gender') {
      setGenderValue(value);
    } else if (name == 'state') {
      setStateValue(value);
    } else if (name == 'city') {
      setCityValue(value);
    }
    formik.setFieldValue(name, value._id);
  };

  return (
    <div className="profile-page p-4">
      <form
        id="profile-update"
        className="form"
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className="row mt-2">
          <div className="col-md-12 col-lg-12 col-xs-12 text-end mb-3">
            <button type="submit" className="btn btn-primary custom-submit-btn" style={{ width: "130px", height: "40px" }}>Save Changes</button>
          </div>
          <div className="col-md-3 col-lg-3 col-xs-12">
            <div className="card custom-card p-3">
              <div className="card-body">
                <div className="d-flex align-items-center flex-column">
                  <div className="rounded-circle-profile mb-4">
                    <img src={formik.values.photoFile} alt="" />
                  </div>
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    onChange={(e)=> handleFileChange(e, 'photo')}
                  />
                  <Button variant="outline-primary upload-btn mb-3 d-flex align-items-center justify-content-center w-145" onClick={handleUploadClick}>
                    <AiOutlineUpload size={20} />{" "}
                    <span style={{ marginLeft: "5px" }}>
                      Upload Photo
                    </span>
                  </Button>{" "}
                  <p className="mb-1 note">
                    <b>Remember:</b> For best results, use an image at least
                    200px by 200px in .jpg or .png format
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9 col-lg-9 col-xs-12">
            <div className="card custom-card p-3">
              <div className="card-header text-left"><Translate>Basic Info</Translate></div>
              <div className="card-body">
                <div className="row text-left">
                  <div className="col-md-4 col-lg-4 col-xs-12 mb-2">
                    <label className="custom-lable mb-2"><Translate>Full Name</Translate></label>
                    <input
                      className="form-control"
                      id="ownerName"
                      name="ownerName"
                      placeholder="Full Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.ownerName}
                    />
                    {formik.touched.ownerName && formik.errors.ownerName ? (
                      <div className="field-error ">
                        {formik.errors.ownerName}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-4 col-lg-4 col-xs-12 mb-2">
                    <label className="custom-lable mb-2"><Translate>Mobile number</Translate></label>
                    <input
                      className="form-control"
                      id="mobileNo"
                      name="mobileNo"
                      placeholder="Mobile number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.mobileNo}
                    />
                    {formik.touched.mobileNo && formik.errors.mobileNo ? (
                      <div className="field-error ">
                        {formik.errors.mobileNo}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-4 col-lg-4 col-xs-12 mb-2">
                    <label className="custom-lable mb-2"><Translate>Email</Translate></label>
                    <input
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="field-error ">{formik.errors.email}</div>
                    ) : null}
                  </div>
                  <div className="col-md-4 col-lg-4 col-xs-12 mb-2">
                    <label className="custom-lable mb-2"><Translate>Gender</Translate></label>
                    <ReactSelect
                      name="gender"
                      value={genderValue}
                      options={options}
                      handleOnChange={selectHandleChange}
                      isSearchable={true}
                      isLoading={false}
                      noOptionsMessage="Gender not availables."
                      placeholder="Select Gender"
                    />
                    {formik.touched.gender && formik.errors.gender && (
                      <div className="field-error ">{formik.errors.gender}</div>
                    )}
                  </div>
                  <div className="col-md-4 col-lg-4 col-xs-12 mb-2">
                    <label className="custom-lable mb-2"><Translate>DOB</Translate></label>
                    <DatePicker
                      selected={formik.values.dob}
                      onChange={(date) => formik.setFieldValue("dob", date)}
                      className="form-control form-control-solid"
                      name="dob"
                      dateFormat="dd/MM/yyyy"
                      showYearDropdown
                      scrollableYearDropdown
                      // yearDropdownItemNumber={15}
                      placeholderText="Select Date of Birth"
                    />
                    {formik.touched.dob && formik.errors.dob && (
                      <div className="field-error ">{formik.errors.dob}</div>
                    )}
                  </div>
                  <div className="col-md-4 col-lg-4 col-xs-12 mb-2">
                    <label className="custom-lable mb-2"><Translate>State</Translate></label>
                    <ReactSelect
                      name="state"
                      value={stateValue}
                      options={states}
                      handleOnChange={selectHandleChange}
                      isSearchable={true}
                      isLoading={false}
                      noOptionsMessage="State not availables."
                      placeholder="Select State"
                    />
                    {formik.touched.state && formik.errors.state && (
                      <div className="field-error ">{formik.errors.state}</div>
                    )}
                  </div>
                  <div className="col-md-4 col-lg-4 col-xs-12 mb-2">
                    <label className="custom-lable mb-2"><Translate>city</Translate></label>
                    <ReactSelect
                      name="city"
                      value={cityValue}
                      options={cities}
                      handleOnChange={selectHandleChange}
                      isSearchable={true}
                      isLoading={false}
                      noOptionsMessage="City not availables."
                      placeholder="Select City"
                    />
                    {formik.touched.city && formik.errors.city && (
                      <div className="field-error ">{formik.errors.city}</div>
                    )}
                  </div>
                  <div className="col-md-8 col-lg-8 col-xs-12">
                    <label className="custom-lable mb-2 mb-2"><Translate>address</Translate></label>
                    <input
                      className="form-control"
                      id="address"
                      name="address"
                      placeholder="Address"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                    />
                    {formik.touched.address && formik.errors.address ? (
                      <div className="field-error ">
                        {formik.errors.address}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-4 col-lg-4 col-xs-12">
            <div className="card custom-card h-320 p-3">
              <div className="card-header text-left"><Translate>Other Details</Translate></div>
              <div className="card-body">
                <div className="row text-left">
                  <div className="col-md-12 col-lg-12 col-xs-12 mb-2">
                    <div className="d-flex justify-content-between">
                      <label className="custom-lable mb-2 mb-2"><Translate>Adhar Card</Translate></label>
                      {showAdharCard && 
                      <div>
                            <Lightbox
                                medium={formik.values.adharPhotoFile}
                                large={profileData.adharPhoto}
                                alt="Adhar Card"
                                hideDownload
                                hideZoom
                                onClose={() => setShowAdharCard(false)}
                      /></div>}
                      <a rel="noopener noreferrer" className="info-value cursor-pointer" onClick={() => setShowAdharCard(true)}><Translate>Click to view</Translate></a>
                    </div>
                    <input
                      type="file"
                      className="form-control"
                      id="adharPhoto"
                      name="adharPhoto"
                      onChange={(e) =>handleFileChange(e, 'adharPhoto')}
                      onBlur={formik.handleBlur}
                      //value={formik.values.adharPhoto}
                    />
                    {formik.touched.adharPhoto && formik.errors.adharPhoto ? (
                      <div className="field-error ">
                        {formik.errors.adharPhoto}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-12 col-lg-12 col-xs-12">
                    <div className="d-flex justify-content-between">
                      <label className="custom-lable mb-2 mb-2"><Translate>Pan Card</Translate></label>
                      {showPanCard && 
                      <div>
                          <Lightbox
                              medium={formik.values.panPhotoFile}
                              large={formik.values.panPhotoFile}
                              alt="Pan Card"
                              hideDownload
                              hideZoom
                              onClose={() => setShowPanCard(false)}
                      /></div>}
                      <a rel="noopener noreferrer" className="info-value cursor-pointer" onClick={() => setShowPanCard(true)}><Translate>Click to view</Translate></a>
                    </div>
                    <input
                      type="file"
                      className="form-control"
                      id="panPhoto"
                      name="panPhoto"
                      onChange={(e) =>handleFileChange(e, 'panPhoto')}
                      onBlur={formik.handleBlur}
                      // value={formik.values.panPhoto}
                    />
                    {formik.touched.panPhoto && formik.errors.panPhoto ? (
                      <div className="field-error ">
                        {formik.errors.panPhoto}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 col-xs-12">
            <div className="card custom-card h-320 p-3">
              <div className="card-header text-left"><Translate>Company Details</Translate></div>
              <div className="card-body">
                <div className="row text-left">
                  <div className="col-md-12 col-lg-12 col-xs-12 mb-2">
                    <label className="custom-lable mb-2 mb-2">
                    <Translate>Name</Translate>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Company Name"
                      id="name"
                      name="name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="field-error ">{formik.errors.name}</div>
                    ) : null}
                  </div>
                  <div className="col-md-12 col-lg-12 col-xs-12 mb-2">
                    <label className="custom-lable mb-2 mb-2"><Translate>address</Translate></label>
                    <input
                      type="text"
                      className="form-control"
                      id="companyAddress"
                      name="companyAddress"
                      placeholder="Company Address"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.companyAddress}
                    />
                    {formik.touched.companyAddress &&
                    formik.errors.companyAddress ? (
                      <div className="field-error ">
                        {formik.errors.companyAddress}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-12 col-lg-12 col-xs-12">
                    <label className="custom-lable mb-2 mb-2">
                    <Translate>Website URL</Translate>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="companyWeb"
                      name="companyWeb"
                      placeholder="Company Website URL"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.companyWeb}
                    />
                    {formik.touched.companyWeb &&
                    formik.errors.companyWeb ? (
                      <div className="field-error ">
                        {formik.errors.companyWeb}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProfileEdit;
