import React, {useState, useEffect} from 'react'
import SVG from "react-inlinesvg";
import redSquare from "../assets/svg/stoppedVehicle.svg"

const StopHistory = (props)=> {

    let {stopData} = props;
    return (
        (stopData.length > 0) ? 
        <>
        {stopData.map((element)=> (
            <div style={{display : "flex" , flexDirection : "column"}}>
            <div className='vehicleStatusSvg' style={{display :"flex" , flexDirection :"row" , height : "30px"}}>
            {/* , justifyItems : "center" , alignItems : "center" */}
            <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    src={redSquare}
                    height={20}
                    width = {20}
                    // style={{marginTop : '6px'}}
                  />
                </span>
                <div className='stoppedStatusAndTime' style={{width : "100%" ,display :"flex" , flexDirection : "row" , justifyContent :"space-between"}}>
                    <p style={{"color": "rgba(226, 71, 63, 1)", "fontSize": "0.8rem", "fontWeight": "600"}}>Stopped</p>
                    <p style={{"color": "rgba(120, 117, 117, 1)", "paddingInline": "1rem", "paddingTop":"0.25rem", "fontSize": "0.7rem", "fontWeight": "200"}}>{element.idleTime}</p>
                </div>
            </div>
            <div>
                <div className='add-detials' style={{display : "flex" , flexDirection : "column", justifyContent : 'flex-start'}}>
                    <p  style={{"color": "rgba(120, 117, 117, 1)", "fontSize": "0.6rem", display : "block"}}>{element.address}</p>
                    <p style={{"color": "rgba(120, 117, 117, 1)", "fontSize": "0.6rem"}}>{`Today ${element.startTimeStamp.split(", ")[1]} to ${element.endTimeStamp.split(", ")[1]}`}</p>
                </div>
            </div>
            <hr/>
        </div>
       
        ))}
        </>
         : (<p>No route history found</p>)
        
    )
}

export default StopHistory