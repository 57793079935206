import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import VehicleElement from "../../components/vehicleElement";
import "./styles.css";
import {Tab,Tabs} from "react-bootstrap";
import { post } from "../../components/api";
import Translate from "../../components/Translate";
import Loading from "../../components/Loading";
import SocketIOComponent from "../../components/SocketIOComponent";
import { getUserIdFromLC } from "../../helpers/helper";

const Vehicles = () => {
  const [vehicleList, setVehicleList] = useState([]);
  const [initialVehicles, setInitialVehicles] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const getAllVehicles = async () => {
    setIsLoading(true);
    const getVehicles = await post("/admin/vehicles");
    if (getVehicles.status == 200) {
      setVehicleList(getVehicles.data);
      setInitialVehicles(getVehicles.data);
      setIsLoading(false);
    } else {
      setVehicleList([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log("getUserIdFromLC()", getUserIdFromLC());
    getAllVehicles();
  }, []);

  async function getTripsByStatus(status) {
    return initialVehicles.filter(trip => trip.tripVehicleStatus == status);
  }

  const handleTabChange = async (key) => {
    if (key == 'all') {
      setVehicleList(initialVehicles);
    } else {
      const vehiclesByStatus = await getTripsByStatus(key);
      setVehicleList(vehiclesByStatus);
    }
  }

  function handleEvent (data) {
    const updatedVehicle = vehicleList.map(vehicle => {
      if (vehicle.vehicleNo === data.vehicleNo) {
        return { ...vehicle, ...data };
      }
      return vehicle;
    });
    setVehicleList(updatedVehicle);
  }
  return (
    <>
      <div className="vehicle">
        <div className="row">
          <Tabs
            defaultActiveKey="all"
            id="uncontrolled-tab-example"
            className="vehicle-tabs mb-4"
            onSelect={handleTabChange}
          >
            <Tab
              eventKey="all"
              title={<Translate>all</Translate>}
              color="black"
              style={{ fontWeight: "bold", fontSize: "0.8rem" }}
            ></Tab>
            <Tab
              eventKey="moving"
              title={<Translate>moving</Translate>}
              color="black"
              style={{ fontWeight: "bold", fontSize: "0.8rem" }}
            ></Tab>
            <Tab
              eventKey="offline"
              title={<Translate>offline</Translate>}
              color="black"
              style={{ fontWeight: "bold", fontSize: "0.8rem" }}
            ></Tab>
            <Tab
              eventKey="idle"
              title={<Translate>idle</Translate>}
              color="black"
              style={{ fontWeight: "bold", fontSize: "0.8rem" }}
            ></Tab>
            <Tab
              eventKey="expired"
              title={<Translate>expired</Translate>}
              color="black"
              style={{ fontWeight: "bold", fontSize: "0.8rem" }}
            ></Tab>
          </Tabs>
          {vehicleList.length > 0 && !isLoading ? (
            <div className="row">
              {vehicleList.map((element, i) => (
                <div className="col-md-6 col-lg-4 mb-4 mx-0" key={`vehicle-${i}`}>
                  <Link to={`/vehicles/${element.vehicleNo}`} state={{ id: element._id }} className="text-decoration-none vehicle-info">
                    <VehicleElement
                      ignitionStatus={element.tripVehicleStatus}
                      address={element.address}
                      routeHistoryKm={element.distance}
                      fuelLevel={element.fuel}
                      totalKmTravelled={element.todayKilometers}
                      vehicleNo={element.vehicleNo}
                      coordinates={element.coordinates}
                      isFuel = {element.isFuel}
                      ignition = {element.ignition}
                      lastRecordTime = {element.createdAt}
                      wireCut = {element.wireCut}
                    />
                  </Link>
                </div>
              ))}
              <SocketIOComponent
                endpoint="http://tsocket.fleetstakes.com:9004"
                event="load_vehicles"
                onEvent={handleEvent}
                clientId={getUserIdFromLC()}
              />
            </div>
          ) : isLoading ? <Loading /> : (
            <p><Translate>noVehicleFound</Translate></p>
          )}
        </div>
      </div>
    </>
  );
};

export default Vehicles;
