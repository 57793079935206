import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Translate from "../../components/Translate";
import englishJson from "../../assets/englishTranlation.json";
import hindiJson from "../../assets/hindiTranslation.json";
import "./style.css"

const ReportsWrapper = () => {
  const [language,setLanguage] = useState('english')

  useEffect(() => {
    let initialLanguage = localStorage.getItem('language')
        setLanguage(initialLanguage)
      }, [language])
      window.addEventListener('storage', ()=> {
        let updatedLang = localStorage.getItem('language');
        setLanguage(updatedLang)
      })
  const reportList = [
    { value: "24Hours", slug: "today-report", label: "hourFleetReport", description :"hourFleetReportDetail" },
    { value: "Monthly", slug: "DaywiseReport", label: "monthlyReport", description :"monthlyReportDetail" },
    // { value: "Monthly", slug: "Monthly", label: "monthlyReport", description :"monthlyReportDetail" },
    // { value: "fuelConsumption", slug: "fuel-consuption-report", label: "fuelConsumptionReport",description :"fuelConsumptionDetail" },
    { value: "idle", slug: "idle-duration-report", label: "idleDuration", description :"idleDurationDetail" },
    { value: "geofence", slug: "geofence-report", label: "geofenceReport", description :"geofenceReportDetail" },
    { value: "Stopage", slug: "stopage-report", label: "StopageReport", description :"stopageReportDetail" },
    { value: "ignition", slug: "ignition-report", label: "ignitionReport", description :"ignitionReportDetail" },
    { value: "ignitiononoff", slug: "ignition-on-off-report", label: "ignitionOnOffReport", description :"ignitionReportDetail" },
    { value: "overspeed", slug: "overspeeding-report", label: "overspeed", description :"overSpeedDetail" },
    { value: "SummaryReport", slug: "Summary-Report", label: "SummaryReport", description :"SummaryReportDetails" },
  ];

  return (
    <div className="reportsWrapper">
      <div className="row">
        {reportList.map((report, i) => (
          <div className="col-md-6 col-lg-4 col-xs-12 mb-3" key={i}>
            <div className="card">
              <div className="card-body">
                <ReportCard report={report} language= {language} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// const ReportCard = ({ report,language }) => {
//   const [showMore, setShowMore] = useState(false);
// let fullDescription = ""
//   const toggleDescription = () => setShowMore(!showMore);
//   if (language === 'english'){
//      fullDescription = englishJson[report?.description] 
//     console.log(fullDescription);
//     console.log(report?.description?.length)
//   } else if (language === 'hindi'){
//     fullDescription = hindiJson[report?.description] 
//     console.log(fullDescription);
//   }
//   const shortDescription = fullDescription.slice(0, 200);
//   return (
//     <div className="reports text-left">
//       <p className="report-title"><Translate>{report?.label}</Translate></p>
//       <p className="report-desc">
//           {showMore ? fullDescription : `${shortDescription}...`}
//         {report?.description?.length > 12 && (
//           <span 
//             className="see-more" 
//             style={{ color: "blue", cursor: "pointer" }}
//             onClick={toggleDescription}
//           >
//             {showMore ? " less" : " more"}
//           </span>
//         )}
//       </p>
//       <Link to={'/fleet-reports/' + report.slug}><Translate>viewDetail</Translate></Link>
//     </div>
//   );
// };

const ReportCard = ({ report, language }) => {
  const [showMore, setShowMore] = useState(false);
  let fullDescription = "";

  const toggleDescription = () => setShowMore(!showMore);

  // Determine the description based on language
  if (language === 'english') {
    fullDescription = englishJson[report?.description] || "";
  } else if (language === 'hindi') {
    fullDescription = hindiJson[report?.description] || "";
  }
  {console.log(fullDescription.length)}

  return (
    <div className="reports text-left">
      <p className="report-title">
        <Translate>{report?.label}</Translate>
      </p>
      <div style={{marginBottom : "30px"}}>
      <p className={`report-desc ${showMore ? "" : "clamp-lines"} mb-0`}>
        {fullDescription}
      </p>
      {fullDescription.length > 270 ? (
        <span
          className="see-more"
          style={{ color: "blue", cursor: "pointer",fontSize : "12px", marginTop : "-1px" }}
          onClick={toggleDescription}
        >
          {showMore ? " Show less" : " Show more"}
        </span>
      ) : <div style={{height : "18px"}}></div>}
      </div>
      <Link to={'/fleet-reports/' + report.slug}>
      <span style={{fontSize : "14px"}}>
        <Translate>viewDetail</Translate>
        </span>
      </Link>
    </div>
  );
};

export default ReportsWrapper;
