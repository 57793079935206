import React from "react";
import { MultiSelect } from "react-multi-select-component";

const Checkbox = ({ options, value, onChange, name = "" }) => {
  const isAllSelected = value?.length === options?.length;
  // width: "240px",
  // , boxShadow: "rgba(0, 0, 0, 0.25) 0px 2px 5px 0px"
  return (
    <div style={{ borderRadius : "5px" }}>
      <MultiSelect
        options={options}
        value={value}
        onChange={onChange}
        labelledBy="Select Vehicles"
        hasSelectAll={true}
        className="ps-menu-checkbox-label"
        valueRenderer={(selected, _options) => {
          const selectedLength = selected?.length;
          if (selectedLength === 0) {
            return (
              <p
                className="ps-menu-checkbox-label"
                style={{ marginTop: "16px" }}
              >
                Select Vehicles....
              </p>
            );
          } else if (selectedLength === options?.length) {
            return (
              <p
                className="ps-menu-checkbox-label"
                style={{ marginTop: "16px" }}
              >{`All ${name} selected `}</p>
            );
          } else {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  
                }}
              >
                <p
                  className="ps-menu-checkbox-label"
                  style={{ marginTop: "16px" }}
                >
                  {`Selected ${name}`}
                </p>
                <span
                  style={{
                    // padding: "1px 5px",
                    height : "18px",
                    width : "18px",
                    borderRadius: "50%",
                    backgroundColor: "#2A7C7A",
                    color : "white",
                    fontWeight: "bold",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {selectedLength}
                </span>
              </div>
            );
          }
        }}
      />
    </div>
  );
};

export default Checkbox;

// import React, { useState } from 'react';
// import Select, { components } from 'react-select';

// // Sample data for the dropdown
// const options = [
//   { value: 'GJ27TD6391', label: 'GJ27TD6391' },
//   { value: 'GJ27TF5069', label: 'GJ27TF5069' },
//   { value: 'GJ01DV7084', label: 'GJ01DV7084' },
//   { value: 'GJ27TF5069AIS', label: 'GJ27TF5069AIS' },
//   { value: 'Executive', label: 'Executive' },
// ];

// // Add a "Select All" option at the top
// const selectAllOption = { value: '*', label: 'Select All' };

// // Function to determine if "Select All" should be checked
// const isSelectAllSelected = (selected) => selected.length === options.length;

// // Function to determine if all individual options are selected
// const areAllOptionsSelected = (selected) =>
//   selected.length === options.length || selected.some((option) => option.value === '*');

// // Custom Option component to render checkboxes
// const CustomOption = ({ innerRef, innerProps, isSelected, data }) => (
//   <div ref={innerRef} {...innerProps} style={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
//     <input
//       type="checkbox"
//       checked={isSelected}
//       onChange={() => {}}
//       style={{ marginRight: '8px' }}
//     />
//     {data.label}
//   </div>
// );

// // Custom MultiValue component for selected values display
// const CustomMultiValue = ({ children, ...props }) => (
//   <components.MultiValue {...props}>
//     <span>{children}</span>
//   </components.MultiValue>
// );

// const DropdownWithCheckboxAndSelectAll = () => {
//   const [selectedOptions, setSelectedOptions] = useState([]);

//   // Handle the selection changes
//   const handleChange = (selected) => {
//     if (selected && selected.length && selected[selected.length - 1].value === selectAllOption.value) {
//       // If "Select All" is selected, return all options
//       if (isSelectAllSelected(selectedOptions)) {
//         setSelectedOptions([]);
//       } else {
//         setSelectedOptions([...options]);
//       }
//     } else {
//       setSelectedOptions(selected || []);
//     }
//   };

//   return (
//     <div style={{ width: '300px', margin: '20px auto' }}>
//       <h4>Select Vehicles</h4>
//       <Select
//         options={[selectAllOption, ...options]} // Include "Select All" option at the beginning
//         isMulti
//         closeMenuOnSelect={false} // Keep the menu open on selecting an option
//         hideSelectedOptions={false}
//         components={{ Option: CustomOption, MultiValue: CustomMultiValue }}
//         onChange={handleChange}
//         value={areAllOptionsSelected(selectedOptions) ? [selectAllOption] : selectedOptions}
//         placeholder="Select Vehicles..."
//         styles={{
//           option: (base) => ({
//             ...base,
//             display: 'flex',
//             alignItems: 'center',
//           }),
//           multiValue: (base) => ({
//             ...base,
//             backgroundColor: '#d0e1f9',
//             borderRadius: '5px',
//           }),
//         }}
//       />
//     </div>
//   );
// };

// export default DropdownWithCheckboxAndSelectAll;
