import React from 'react';
import Table from 'react-bootstrap/Table';

const ResponsiveTable = ({data }) => {
  console.log(data)
  if (data.length === 0) return null;
  const excludeColumns = ['_id', 'companyId']
  const columns = Object.keys(data[0]).filter(column => !excludeColumns.includes(column));
  return (
    <Table responsive className='sticky-header report-table table-striped mb-0'>
      <thead class="thead-dark">
        <tr>
          {columns.map((column, index) => (
            <th key={index} className={column == 'Address' && 'report-address'} style={column === 'address' ? { width: '600px' } : column === "Location" ? {width : "400px"} : {}}>{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((column, colIndex) => (
              <td key={colIndex} className={column == 'Address' && 'report-address'}>{row[column]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ResponsiveTable;
