import React, { useEffect, useState, useRef } from "react";
import { GoogleMap, useLoadScript, Marker, InfoWindow, MarkerClusterer } from '@react-google-maps/api';
import SVG from "react-inlinesvg";
import View from "../assets/emergency_services/view.svg";
import RunningVehicle from "../assets/images/running_vehicle.png";
import IdleVehicle from "../assets/images/idle_vehicle.png";
import StoppedVehicle from "../assets/images/stopped_vehicle.png";
import { Link } from "react-router-dom";

const libraries = ['places'];

const mapContainerStyle = {
  width: '100%',
  height: '500px',
};

export default function MapContainer(props) {
  const { locations1, selectVehicleNo } = props;
  const [mapType, setMapType] = useState('roadmap');
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState("");
  const [mapBound , setMapBound] = useState(null);
  const [zoom, setZoom] = useState(14);
  const mapRef = useRef(null);

  useEffect(() => {
    console.log("Locations updated:", locations1);
    
    if (mapRef.current && locations1.length > 0) {
      // Fit bounds every time locations1 updates
      const bounds = new window.google.maps.LatLngBounds();
      locations1.forEach(marker => {
        if (marker.lat && marker.lng) {
          bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng));
        }
      });
      // setMapBound(bounds)
      mapRef.current.fitBounds(bounds);
      // setZoom(mapRef.current.getZoom())
      console.log("Map bounds set to:", mapRef.current.getCenter());
    }
    // else {
    //   setMapBound(null)
    //   setZoom(8)
    // }
  }, [locations1, mapRef.current]);

  const handleLoadMap = (map) => {
    mapRef.current = map;
    if (locations1.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      locations1.forEach(marker => {
        if (marker.lat && marker.lng) {
          bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng));
        }
      });
      // setMapBound(bounds);
      map.fitBounds(bounds);
    }
    // else {
    // //  setMapBound(null);
    // //  setZoom(8);
    // }
   
  };
  // Set the map bounds to include all markers initially
   // This effect runs when the map is loaded
  //  useEffect(() => {
  //   if (mapRef.current) {
  //     // Optionally log the center or perform other actions when map loads
  //     console.log("Map has loaded.");
  //   }
  // }, [mapRef.current]);

  // // This effect runs whenever locations1 changes
  // useEffect(() => {
  //   console.log("Locations updated:", locations1);
  //   if (locations1.length > 0 && mapRef.current) {
  //     const bounds = new window.google.maps.LatLngBounds();
  //     locations1.forEach(marker => {
  //       if (marker.lat && marker.lng) {
  //         bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng));
  //       }
  //     });
  //     mapRef.current.fitBounds(bounds);
  //     console.log("Map bounds set to:", mapRef.current.getCenter());
  //   }
  // }, [locations1]);
  // useEffect(() => {
  //   console.log("1",locations1)
  //   console.log("2",mapRef?.current?.center)
  //   if (locations1.length > 0 && mapRef.current) {
  //     const bounds = new window.google.maps.LatLngBounds();
  //     locations1.forEach(marker => {
  //       if (marker.lat && marker.lng) {
  //         bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng));
  //       }
  //     });
  //     mapRef.current.fitBounds(bounds);
  //     console.log("3",mapRef?.current?.center);
  //   }
  // }, [locations1]);

  useEffect(() => {
    const selectedIndex = locations1.findIndex(markerData => markerData.vehicleNo === selectVehicleNo);
    setSelectedMarkerIndex(selectedIndex !== -1 ? selectedIndex : null);
  }, [selectVehicleNo, locations1]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  const toggleMapType = (type) => {
    if (type === 'view-type') {
      const newMapType = mapType === 'roadmap' ? 'satellite' : 'roadmap';
      setMapType(newMapType);
    }
  };

  const mapOptions = {
    mapTypeId: mapType,
    zoomControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
  };

  const smoothZoom = (map, targetZoom) => {
    let currentZoom = map.getZoom();
    const step = targetZoom > currentZoom ? 1 : -1;
    function zoom() {
      if (currentZoom !== targetZoom) {
        currentZoom += step;
        map.setZoom(currentZoom);
        setTimeout(zoom, 300);
      }
    }
    zoom();
  };

  // Function to handle marker click and smooth zoom in
  const handleToggleInfoWindow = (index) => {
    if (index !== "") {
      setSelectedMarkerIndex(index);
      const position = locations1[index];

      // Pan to the selected vehicle and smoothly zoom in
      mapRef.current.panTo({ lat: position.lat, lng: position.lng });
      smoothZoom(mapRef.current, 12); // Smoothly zoom in to level 15
    } else {
      // Close InfoWindow and smoothly zoom out to level 10
      setSelectedMarkerIndex("");
      smoothZoom(mapRef.current, 10); // Zoom out to level 10
    }
  };

  return (
    <div>
      <div className="google-map-parent">
      <GoogleMap
        onLoad={handleLoadMap}
        mapContainerStyle={mapContainerStyle}
        options={mapOptions}
        // zoom={zoom}
        // center={
        //   mapBound // Use stored bounds for center
        //     ? {
        //         lat: mapBound.getCenter().lat(),
        //         lng: mapBound.getCenter().lng(),
        //       }
        //     :  { lat:23.020561, lng: 72.583612 }}
      >
        <MarkerClusterer>
          {(clusterer) =>
            locations1.map((markerData, index) => (
              <Marker 
                key={markerData?.vehicleNo}
                icon={{
                  url: markerData.vehicleStatus === 'moving' ? RunningVehicle : markerData.vehicleStatus === 'idle' ? IdleVehicle : StoppedVehicle,
                  scaledSize: { width: 50, height: 50 },
                }}
                position={{ lat: markerData.lat, lng: markerData.lng }} 
                clusterer={clusterer} 
                onClick={() => handleToggleInfoWindow(index)}
              >
                {selectedMarkerIndex !== '' && selectedMarkerIndex === index && (
                  <InfoWindow
                    onCloseClick={() => handleToggleInfoWindow("")} // Close info window
                  >
                    <div style={{ color: '#fff', width: '250px', display: 'flex', flexDirection: 'row', textAlign: 'left', padding: '5px', alignItems: "baseline"}} className="live">
                      <div>
                        <button className='speed-button'>{markerData?.speed || 0}</button>
                      </div>
                      <div style={{ padding: '5px' }} className="live">
                        <Link to={`/vehicles/${markerData.vehicleNo}`} state={{ id: markerData.id }} className="text-white font-600">
                          <p className='mb-2'>{markerData.vehicleNo}</p>
                        </Link>
                        <p>{markerData.address}</p>
                      </div>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            ))
          }
        </MarkerClusterer>
      </GoogleMap>
      </div>
      <div className='map-custom-icons-dashboard'>
        <div className={mapType === 'roadmap' ? `image-container mt-3 mb-2` : `image-container-active mt-3 mb-2`} onClick={() => toggleMapType('view-type')}>
          <SVG src={View} width={'20px'} />
        </div>
      </div>
    </div>
  );
}


// import React, { useEffect, useState, useRef } from "react";
// import { GoogleMap, useLoadScript, Marker, InfoWindow, MarkerClusterer } from '@react-google-maps/api';
// import SVG from "react-inlinesvg";
// import View from "../assets/emergency_services/view.svg";
// import RunningVehicle from "../assets/images/running_vehicle.png";
// import IdleVehicle from "../assets/images/idle_vehicle.png";
// import StoppedVehicle from "../assets/images/stopped_vehicle.png";
// import { Link } from "react-router-dom";

// const libraries = ['places'];

// const mapContainerStyle = {
//   width: '100%',
//   height: '500px',
// };

// export default function MapContainer(props) {
//   const { locations1, selectVehicleNo } = props;
//   const [mapType, setMapType] = useState('roadmap');
//   const [selectedMarkerIndex, setSelectedMarkerIndex] = useState("");
//   const mapRef = useRef(null);

//   // Helper function to calculate the center of the given coordinates
//   const getCenterOfLocations = (locations) => {
//     if (!locations.length) return { lat: 0, lng: 0 };
//     let latSum = 0;
//     let lngSum = 0;

//     locations.forEach((location) => {
//       latSum += location.lat;
//       lngSum += location.lng;
//     });

//     return { lat: latSum / locations.length, lng: lngSum / locations.length };
//   };

//   // Calculate the center of all vehicle locations
//   const mapCenter = getCenterOfLocations(locations1);

//   useEffect(() => {
//     // Find the index of the selected vehicle based on its vehicle number
//     const selectedIndex = locations1.findIndex(markerData => markerData.vehicleNo === selectVehicleNo);
//     setSelectedMarkerIndex(selectedIndex !== -1 ? selectedIndex : null);
//   }, [selectVehicleNo, locations1]);

//   useEffect(() => {
//     if (selectedMarkerIndex !== null && mapRef.current) {
//       const bounds = new window.google.maps.LatLngBounds();
//       if (locations1[selectedMarkerIndex]?.lat && locations1[selectedMarkerIndex]?.lng) {
//         bounds.extend(new window.google.maps.LatLng(locations1[selectedMarkerIndex].lat, locations1[selectedMarkerIndex].lng));
//         mapRef.current.fitBounds(bounds);
//         mapRef.current.setZoom(14);
//       }
//     } else {
//       const bounds = new window.google.maps.LatLngBounds();
//       if (locations1[0]?.lat && locations1[0]?.lng && mapRef.current) {
//         bounds.extend(new window.google.maps.LatLng(locations1[selectedMarkerIndex || 0].lat, locations1[selectedMarkerIndex || 0].lng));
//         mapRef.current.fitBounds(bounds);
//         mapRef.current.setZoom(10);
//       }
//     }
//   }, [selectedMarkerIndex, locations1]);

//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
//     libraries,
//   });

//   if (loadError) {
//     return <div>Error loading maps</div>;
//   }

//   if (!isLoaded) {
//     return <div>Loading maps</div>;
//   }

//   const toggleMapType = (type) => {
//     if (type === 'view-type') {
//       const newMapType = mapType === 'roadmap' ? 'satellite' : 'roadmap';
//       setMapType(newMapType); // Update mapType state
//     }
//   };

//   const mapOptions = {
//     mapTypeId: mapType,
//     zoomControl: true,
//     streetViewControl: false, // Disable street view control
//     fullscreenControl: false, // Disable fullscreen control
//     mapTypeControl: false, // Disable map type control
//     trafficControlOptions: {
//       mapTypeIds: ['roadmap', 'satellite'],
//       style: window.google.maps.TrafficStyle
//     }
//   };

//   const handleToggleInfoWindow = (index) => {
//     setSelectedMarkerIndex(index);
//     mapRef.current.setZoom(10);
//   };

//   return (
//     <div>
//       <GoogleMap
//         onLoad={map => (mapRef.current = map)}
//         mapContainerStyle={mapContainerStyle}
//         zoom={10}
//         center={mapCenter} // Set the calculated center
//         options={mapOptions}
//       >
//         {/* MarkerClusterer Component */}
//         <MarkerClusterer
//           averageCenter
//           enableRetinaIcons
//           gridSize={120} // Customize cluster grid size
//           minimumClusterSize={2} // Set minimum markers per cluster
//           options={{
//             styles: [
//               {
//                 url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png",
//                 height: 53,
//                 width: 53,
//                 textColor: "#fff",
//                 textSize: 14,
//               },
//             ],
//           }}
//         >
//           {(clusterer) =>
//             locations1.map((markerData, index) => (
//               <Marker
//                 key={markerData?.vehicleNo}
//                 icon={{
//                   url: markerData.vehicleStatus === 'moving' ? RunningVehicle : markerData.vehicleStatus === 'idle' ? IdleVehicle : StoppedVehicle,
//                   scaledSize: { width: 50, height: 50 },
//                   scale: 7
//                 }}
//                 position={{ lat: markerData.lat, lng: markerData.lng }}
//                 clusterer={clusterer}
//                 onClick={() => handleToggleInfoWindow(index)}
//               >
//                 {selectedMarkerIndex !== '' && selectedMarkerIndex === index && (
//                   <InfoWindow
//                     onCloseClick={() => handleToggleInfoWindow(index)} // Close info window on click outside
//                   >
//                     <div style={{ color: '#fff', width: '250px', display: 'flex', flexDirection: 'row', textAlign: 'left', padding: '5px', alignItems: "baseline" }} className="live">
//                       <div>
//                         <button className='speed-button'>{markerData?.speed || 0}</button>
//                       </div>
//                       <div style={{ padding: '5px' }} className="live">
//                         <Link to={`/vehicles/${markerData.vehicleNo}`} state={{ id: markerData.id }} className="text-white font-600">
//                           <p className='mb-2'>{markerData.vehicleNo}</p>
//                         </Link>
//                         <p>{markerData.address}</p>
//                       </div>
//                     </div>
//                   </InfoWindow>
//                 )}
//               </Marker>
//             ))
//           }
//         </MarkerClusterer>
//       </GoogleMap>
//       <div className='map-custom-icons-dashboard'>
//         <div className={mapType === 'roadmap' ? `image-container mt-3 mb-2` : `image-container-active mt-3 mb-2`} onClick={() => toggleMapType('view-type')}>
//           <SVG src={View} width={'20px'} />
//         </div>
//       </div>
//     </div>
//   );
// }



// import React, { useEffect, useState, useRef } from "react";
// import { GoogleMap, useLoadScript, Marker, InfoWindow, MarkerClusterer } from '@react-google-maps/api';
// import SVG from "react-inlinesvg";
// import View from "../assets/emergency_services/view.svg";
// import RunningVehicle from "../assets/images/running_vehicle.png";
// import IdleVehicle from "../assets/images/idle_vehicle.png";
// import StoppedVehicle from "../assets/images/stopped_vehicle.png";
// import { Link } from "react-router-dom";

// const libraries = ['places'];

// const mapContainerStyle = {
//   width: '100%',
//   height: '500px',
// };

// export default function MapContainer(props) {
//   const { locations1, selectVehicleNo } = props;
//   const [mapType, setMapType] = useState('roadmap');
//   const [selectedMarkerIndex, setSelectedMarkerIndex] = useState("");
//   // cosnt
//   const mapRef = useRef(null);


//   useEffect(() => {
//     // Find the index of the selected vehicle based on its vehicle number
//     const selectedIndex = locations1.findIndex(markerData => markerData.vehicleNo === selectVehicleNo);
//     setSelectedMarkerIndex(selectedIndex !== -1 ? selectedIndex : null);
//   }, [selectVehicleNo, locations1]);

//   useEffect(() => {
//     if (selectedMarkerIndex !== null && mapRef.current) {
//       const bounds = new window.google.maps.LatLngBounds();
//       if (locations1[selectedMarkerIndex]?.lat && locations1[selectedMarkerIndex]?.lng) {
//         bounds.extend(new window.google.maps.LatLng(locations1[selectedMarkerIndex].lat, locations1[selectedMarkerIndex].lng));
//         mapRef.current.fitBounds(bounds);
//         mapRef.current.setZoom(14);
//       }
//     } else {
//       const bounds = new window.google.maps.LatLngBounds();
//       if (locations1[0]?.lat && locations1[0]?.lng && mapRef.current) {
//         bounds.extend(new window.google.maps.LatLng(locations1[selectedMarkerIndex || 0].lat, locations1[selectedMarkerIndex || 0].lng));
//         mapRef.current.fitBounds(bounds);
//         mapRef.current.setZoom(10);
//       }
//     }
//   }, [selectedMarkerIndex, locations1]);

//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
//     libraries,
//   });

//   if (loadError) {
//     return <div>Error loading maps</div>;
//   }

//   if (!isLoaded) {
//     return <div>Loading maps</div>;
//   }

//   const toggleMapType = (type) => {
//     if (type == 'view-type') {
//       const newMapType = mapType === 'roadmap' ? 'satellite' : 'roadmap';
//       setMapType(newMapType); // Update mapType state
//     }
//   };

//   const mapOptions = {
//     mapTypeId: mapType,
//     zoomControl: true,
//     streetViewControl: false, // Disable street view control
//     fullscreenControl: false, // Disable fullscreen control
//     mapTypeControl: false, // Disable map type control
//     trafficControlOptions: {
//       mapTypeIds: ['roadmap', 'satellite'],
//       style: window.google.maps.TrafficStyle
//     }
//   }

//   const handleToggleInfoWindow = (index) => {
//     setSelectedMarkerIndex(index);
//     mapRef.current.setZoom(10);
//   }

  
  
//   return (
//     <div>
//        <GoogleMap
//           onLoad={map => (mapRef.current = map)}
//           mapContainerStyle={mapContainerStyle}
//           zoom={10}
//           // center={mapCenter}
//           center={{lat: locations1[0]?.lat, lng: locations1[0]?.lng}}
//           options={mapOptions}
//         >
//           <MarkerClusterer>
//           {(clusterer) =>
//             locations1.map((markerData, index) => (
//               <Marker 
//                 key={markerData?.vehicleNo}
//                 icon={{
//                   url: markerData.vehicleStatus == 'moving' ? RunningVehicle : markerData.vehicleStatus == 'idle' ? IdleVehicle : StoppedVehicle,
//                   scaledSize: { width: 50, height: 50 },
//                   scale: 7
//                 }}
//                 position={{ lat: markerData.lat, lng: markerData.lng }} 
//                 clusterer={clusterer} 
//                 onClick={() => handleToggleInfoWindow(index)}
//               >
//                 {selectedMarkerIndex !== '' && selectedMarkerIndex == index && (
//                   <InfoWindow
//                     onCloseClick={() => handleToggleInfoWindow("")} // Close info window on click outside
//                   >
//                     <div style={{ color: '#fff', width: '250px', display: 'flex', flexDirection: 'row', textAlign: 'left', padding: '5px', alignItems: "baseline"}} className="live">
//                         <div>
//                           <button  className='speed-button'>{markerData?.speed || 0}</button>
//                         </div>
//                       <div style={{padding : '5px'}} className="live">
//                       <Link to={`/vehicles/${markerData.vehicleNo}`} state={{ id: markerData.id }} className="text-white font-600">
//                         <p className='mb-2'>{markerData.vehicleNo}</p>
//                       </Link>
//                         <p>{markerData.address}</p>
//                       </div>
//                       </div>
//                   </InfoWindow>
//                 )}
//               </Marker>
//             ))
//           }
//         </MarkerClusterer>
//         </GoogleMap>
//         <div className='map-custom-icons-dashboard'>
//           <div className={mapType == 'roadmap' ? `image-container mt-3 mb-2` : `image-container-active mt-3 mb-2`} onClick={() => toggleMapType('view-type')}>
//             <SVG src={View} width={'20px'} />
//           </div>
//         </div>
//     </div>
//   );
// }