import React, { useState, useEffect } from "react";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import { IoSearch } from "react-icons/io5";
import { LuTimer } from "react-icons/lu";
import { FiMapPin } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import MapContainer from "../../components/MapContainer";
import ApexChart from "../../components/ApexChart";
import { get, post } from "../../components/api";
import moment from "moment";
import "./styles.css";
import Loading from "../../components/Loading";
import Translate from "../../components/Translate";
import DialogBox from "../../components/DialogBox";
import { FcBrokenLink } from "react-icons/fc";
import { PiPlugsConnectedFill } from "react-icons/pi";

const Dashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [selectedVehicleNo, setSelectedVehicleNo] = useState("");
  const [selectVehicleNo, setSelectVehicleNo] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [vehicleLocations, setVehicleLocations] = useState([]);
  const [trackVehicle, setTrackVehicle] = useState([]);
  const [fuelConsumption, setFuelConsumption] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [activeVehicleList, setActiveVehicleList] = useState([]);
  const [vehicleStatus, setVehicleStatus] = useState({
    offline: 0,
    idle: 0,
    moving: 0,
  });
  const [documentList, setDocumentList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [gpsSubs, setGpsSubs] = useState([0, 0, 0]);
  const [gpsCount, setGpsCount] = useState(0);
  const [gpsFuelSubs, setGpsFuelSubs] = useState([0, 0, 0]);
  const [gpsFuelCount, setGpsFuelCount] = useState(0);
  const [tagnames, setTagNames] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 480);

  let userData = localStorage.getItem("ftmUserData");

  if (userData) {
    try {
      userData = JSON.parse(userData);
    } catch (error) {
      console.error("Error parsing user data:", error);
    }
  } else {
    console.log("No user data found in localStorage");
    navigate("/login");
  }

  const handleTrackVehicle = async () => {
    const apiResponse = await get("/admin/all-vehicle-current-loc");
    if (apiResponse.status == 200) {
      let locations = [];
      let res = apiResponse.data;
      if (res.length > 0) {
        const statusCounts = { ...vehicleStatus };
        for (let location of res) {
          locations.push({
            lat: location.coordinates[0],
            lng: location.coordinates[1],
            vehicleStatus: location.vehicle.tripVehicleStatus,
            vehicleNo: location.vehicleNo,
            address: location.address,
            id: location._id,
            show: false,
            speed: location.speed,
          });
          if (location.vehicle.tripVehicleStatus) {
            if (statusCounts[location.vehicle.tripVehicleStatus]) {
              statusCounts[location.vehicle.tripVehicleStatus]++;
            } else {
              statusCounts[location.vehicle.tripVehicleStatus] = 1;
            }
          }
        }
        setVehicleStatus(statusCounts);
      }
      setVehicleLocations(locations);
      setTrackVehicle(locations);
    } else {
      setTrackVehicle([]);
    }
  };

  const handleFuelStats = async () => {
    const apiResponse = await post("/fuel-level-stats", {
      vehicleNo: "GJ27TD6391",
    });
    if (apiResponse.status == 200) {
      setFuelConsumption(apiResponse.data);
    } else {
      setFuelConsumption([]);
    }
  };

  const handleActiveVehicle = async () => {
    setLoading(true);
    const apiResponse = await post("/get-most-active-vehicle", {});
    if (apiResponse.status == 200) {
      setActiveVehicleList(apiResponse.data);
      setVehicleList(apiResponse.data);
      setLoading(false);
    } else {
      setActiveVehicleList([]);
      setVehicleList([]);
      setLoading(false);
    }
  };

  const handleDocumentList = async () => {
    const apiResponse = await post("/admin/document/expiration-list", {});
    if (apiResponse.status == 200) {
      setDocumentList(apiResponse.data);
    } else {
      setDocumentList([]);
    }
  };

  const handleSubs = async (type) => {
    const apiRes = await post("/get-subscriptions", { planType: type });
    if (apiRes.status == 200) {
      if (type == "GPS") {
        setGpsSubs([
          apiRes.data.expiringSoon,
          apiRes.data.active,
          apiRes.data.expired,
        ]);
        setGpsCount(
          apiRes.data.expiringSoon + apiRes.data.active + apiRes.data.expired
        );
      } else {
        setGpsFuelSubs([
          apiRes.data.expiringSoon,
          apiRes.data.active,
          apiRes.data.expired,
        ]);
        setGpsFuelCount(
          apiRes.data.expiringSoon + apiRes.data.active + apiRes.data.expired
        );
      }
    } else {
      if (type == "GPS") {
        setGpsSubs([0, 0, 0]);
        setGpsCount(0);
      } else {
        setGpsFuelSubs([0, 0, 0]);
        setGpsFuelCount(0);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    handleTrackVehicle();
    handleFuelStats();
    handleActiveVehicle();
    handleDocumentList();
    handleSubs("GPS");
    handleSubs("GPS_FUEL");
    setIsLoading(false);
  }, []);

  // const getDocumentType = (type) => {
  //   let documentType;
  //   switch (type) {
  //     case "license":
  //       documentType = "License";
  //       break;
  //     case "insurance":
  //       documentType = "Insurance";
  //       break;
  //     case "rc":
  //       documentType = "Registration Certificate (RC)";
  //       break;
  //     case "puc":
  //       documentType = "Pollution Under Control (PUC)";
  //       break;

  //     default:
  //       break;
  //   }

  //   return documentType;
  // };
  // PI chart
  // const PIseries = [10, 5, 20];
  const PIOptions = {
    chart: {
      width: 480,
      type: "pie",
      toolbar: {
        show: true,
        tools: {
          download: true,
        },
      },
    },
    labels: ["Expiring Soon", "Active", "Expired"],
    legend: {
      position: "left",
      horizontalAlign: "top",
      fontSize: "12px",
      fontWeight: 600,
      markers: {
        width: 14,
        height: 14,
        radius: 0,
      },
      itemMargin: {
        vertical: 5,
      },
    },
    colors: ["#F29425", "#10A142", "#E54F53"],
    responsive: [
      {
        breakpoint: 380,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  // Line Chart
  // const Lineseris = [
  //   {
  //     name: "GJ27TD6391",
  //     data: fuelConsumption.reverse(),
  //   },
  // ];
  // const LineOptions = {
  //   chart: {
  //     type: "area",
  //     stacked: false,
  //     height: 250,
  //     zoom: {
  //       type: "x",
  //       enabled: true,
  //       autoScaleYaxis: true,
  //     },
  //     toolbar: {
  //       show: true,
  //       autoSelected: "zoom",
  //       tools: {
  //         download: true,
  //         zoom: false,
  //         pan: false,
  //         reset: false,
  //         zoomin: false,
  //         zoomout: false,
  //       },
  //     },
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   markers: {
  //     size: 0,
  //   },
  //   fill: {
  //     type: "gradient",
  //     gradient: {
  //       shadeIntensity: 1,
  //       inverseColors: false,
  //       opacityFrom: 0.5,
  //       opacityTo: 0,
  //       stops: [0, 90, 100],
  //     },
  //   },
  //   yaxis: {
  //     labels: {
  //       formatter: function (val) {
  //         return val.toFixed(0);
  //       },
  //     },
  //     title: {
  //       text: "Diesel Level",
  //     },
  //   },
  //   xaxis: {
  //     type: "datetime",
  //   },
  //   tooltip: {
  //     shared: false,
  //     y: {
  //       formatter: function (val) {
  //         return val.toFixed(0);
  //       },
  //     },
  //   },
  // };

  const filterVehiclesByVehicleNo = (vehicles, searchString) => {
    const lowerCaseSearchString = searchString.toLowerCase();
    const searchedVehicle = vehicles.filter((vehicle) =>
      vehicle?.vehicleNo?.toLowerCase().includes(lowerCaseSearchString)
    );
    const searchedVehicleByTag = vehicles.filter((vehicle) =>
      vehicle?.tags?.some((tag) => tag.tagname.includes(lowerCaseSearchString))
    );
    const uniqueItems = [...searchedVehicle, ...searchedVehicleByTag].filter(
      (item, index) =>
        [...searchedVehicle, ...searchedVehicleByTag].indexOf(item) === index
    );
    return uniqueItems;
  };

  const searchVehicle = (event) => {
    if (event.target.value !== "") {
      const filteredVehicles = filterVehiclesByVehicleNo(
        activeVehicleList,
        event.target.value
      );
      setActiveVehicleList(filteredVehicles);
    } else {
      setActiveVehicleList(vehicleList);
    }
  };

  const IconWithAddress = ({ vehicle }) =>
    vehicle?.address && (
      <div className="d-flex align-items-start">
        <FiMapPin style={{ fontSize: "1em" }} />{" "}
        {/* Adjust the size as needed */}
        <span className="ml-2 today-km w-100">{vehicle?.address}</span>
      </div>
    );

  const handleCloseModal = async () => {
    setModalShow(false); // Close the dialog box
    await handleActiveVehicle(); // Refresh vehicle info after closing dialog
  };

  const handleAddTag = () => {
    handleCloseModal();
  };

  const addOrUpdateTag = (event, id, number) => {
    event.stopPropagation();
    const selectedVehicle = activeVehicleList?.find(
      (vehicle) => vehicle._id === id
    );
    setTagNames(selectedVehicle.tags);
    setSelectedVehicle(id);
    setSelectedVehicleNo(number);
    setModalShow(true);
  };

  const updateTag = (id, vehicleId) => {
    const updatedTagname = tagnames.filter((tagname) => tagname._id !== id);
    setTagNames(updatedTagname);

    const updatedActiveVehicleList = activeVehicleList?.map((vehicle) => {
      if (vehicle._id === vehicleId) {
        const updatedTags = vehicle.tags.filter((tag) => tag._id !== id);
        return { ...vehicle, tags: updatedTags }; // Refresh vehicle info after deleting tag
      }
      return vehicle;
    });
    setActiveVehicleList(updatedActiveVehicleList);
  };

  const selectVehicle = (vehicleNo) => {
    setSelectVehicleNo(vehicleNo);
  };

  // fiilter vehicle by status
  const filtervehicleByStatus = (type) => {
    if (type === "all") {
      setTrackVehicle(vehicleLocations);
      setActiveVehicleList(vehicleList);
      setSelectVehicleNo("");
    } else {
      const data = vehicleLocations.filter(
        (vehicle) => vehicle?.vehicleStatus === type
      );
      const vehiclesData = vehicleList.filter(
        (vehicle) => vehicle?.tripVehicleStatus === type
      );
      setTrackVehicle(data);
      setActiveVehicleList(vehiclesData);
    }
  };
  return (
    <div className="dashboard">
      {!isLoading ? (
        <>
          <div className="row mb-5">
            <div className="col-md-12 col-lg-12 col-xs-12 introduction">
              <p className="mb-0 font-bolder">
                👋 <Translate>hey</Translate>, {userData?.ownerName}
              </p>
              <p className="dashboard-desc">
                <Translate>fleetOverViewDashboard</Translate>
              </p>
            </div>
            <div className="col-md-5 col-lg-4 col-xs-12">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-xs-12">
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      <IoSearch />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Vehicle"
                      aria-label="Vehicle"
                      aria-describedby="basic-addon1"
                      onChange={searchVehicle}
                    />
                  </div>
                  <div className="vehicle-list-parent">
                    {activeVehicleList.length <= 0 && loading && <Loading />}
                    {activeVehicleList.length <= 0 && !loading && (
                      <div className="">No vehicles found</div>
                    )}
                    {activeVehicleList.length > 0 &&
                      !loading &&
                      activeVehicleList?.map((vehicle, index) => (
                        <div
                          className={
                            vehicle.vehicleNo == selectVehicleNo
                              ? `card text-center mb-2 selected-vehicle cursor-pointer`
                              : `card text-center mb-2 cursor-pointer`
                          }
                          onClick={() => selectVehicle(vehicle.vehicleNo)}
                        >
                          <div className="card-body">
                            <div className="vehicle-details">
                              <div style={{display : 'flex' ,alignItems :"baseline" , justifyContent : "space-between"}}>
                              <p className="mb-1 vehicle-no">
                                {vehicle?.vehicleNo}
                              </p>
                              {vehicle.wireCut ? (
                                <div
                                  style={{
                                    backgroundColor: "orange",
                                    padding: "2px",
                                    borderRadius: "6px",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    paddingInline: "4px",
                                  }}
                                >
                                  <p
                                    className="vehicleStatusText"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <FcBrokenLink
                                      color="red"
                                      size={"14px"}
                                      style={{ marginRight: "5px" }}
                                    />
                                    <span style={{ fontWeight: "500" }}>
                                      Wirecut
                                    </span>
                                  </p>
                                </div>
                              ) : (
                                <></>
                              )}
                              </div>
                              <div className="d-flex align-items-start">
                                <LuTimer size={16} />
                                <p className="mb-1 ml-2 today-km">
                                  {vehicle?.todayKilometers} km{" "}
                                  <Translate>travelledToday</Translate> -{" "}
                                  {moment(vehicle?.createdAt).format(
                                    "DD/MM/YYYY hh:mm A"
                                  )}
                                </p>
                              </div>
                              <IconWithAddress vehicle={vehicle} />
                            </div>
                          </div>
                          <div className="card-footer text-muted">
                            <Stack direction="horizontal" gap={2}>
                              <Badge
                                bg={
                                  vehicle.tripVehicleStatus == "moving"
                                    ? "success"
                                    : vehicle.tripVehicleStatus == "idle"
                                    ? "warning"
                                    : "danger"
                                }
                              >
                                {" "}
                                <Translate>
                                  {vehicle.tripVehicleStatus}
                                </Translate>
                              </Badge>
                              {vehicle?.tags?.map((tag) => (
                                <Badge>{tag.tagname}</Badge>
                              ))}
                              <Badge
                                bg="secondary"
                                onClick={(event) =>
                                  addOrUpdateTag(
                                    event,
                                    vehicle._id,
                                    vehicle.vehicleNo
                                  )
                                }
                                // className="ms-auto"
                              >
                                {" "}
                                <Translate>addtag</Translate>
                              </Badge>
                            </Stack>
                          </div>
                        </div>
                      ))}
                    <DialogBox
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                      vehicleId={selectedVehicle}
                      vehicleNo={selectedVehicleNo}
                      tags={tagnames}
                      handleAddTag={handleAddTag}
                      updateTag={updateTag}
                    />
                  </div>
                </div>
                {/* <div className="col-md-12 mt-3 d-none d-md-block d-lg-none">
                  <div className="card custom-card">
                    <div className="card-body h-314">
                      <div className="d-flex justify-content-between">
                        <h6>
                          <Translate>documentExpiration</Translate>
                        </h6>
                        <Link to={"/documents"} className="view-all">
                          view all
                        </Link>
                      </div>
                      <div className="document-list">
                        {documentList.length > 0 && documentList.map((document, index) => (
                          <div className="list" key={`vehicle-detail-${index}`}>
                            <div className="vehicle-details">
                              <p className="mb-1 vehicle-no">
                                {document.vehicleNo}
                              </p>
                              <p className="mb-1 today-km">
                                {getDocumentType(document.documentType)}
                              </p>
                              <p className="mb-1 doc-expired">
                                {moment(document.documentExpirationDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-md-7 col-lg-8 col-xs-12">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-xs-12">
                  <div className="card custom-card">
                    <div className="card-header d-flex justify-content-between">
                      <h6>
                        <Translate>map</Translate>
                      </h6>
                      <div className="d-flex">
                        <a
                          className="mb-1 vehicle-filter"
                          onClick={() => filtervehicleByStatus("all")}
                        >
                          <Translate>allVehicles</Translate> (
                          {vehicleList.length})
                        </a>
                        <span
                          style={{
                            margin: isSmallScreen ? "0px 4px" : "0px 10px",
                          }}
                        >
                          |
                        </span>
                        <a
                          className="mb-1 vehicle-filter"
                          onClick={() => filtervehicleByStatus("moving")}
                        >
                          <Translate>running</Translate> (
                          {vehicleStatus?.moving})
                        </a>
                        <span
                          style={{
                            margin: isSmallScreen ? "0px 4px" : "0px 10px",
                          }}
                        >
                          |
                        </span>
                        <a
                          className="mb-1 vehicle-filter"
                          onClick={() => filtervehicleByStatus("idle")}
                        >
                          <Translate>idle</Translate> ({vehicleStatus?.idle})
                        </a>
                        <span
                          style={{
                            margin: isSmallScreen ? "0px 4px" : "0px 10px",
                          }}
                        >
                          |
                        </span>
                        <a
                          className="mb-1 vehicle-filter"
                          onClick={() => filtervehicleByStatus("offline")}
                        >
                          <Translate>offline</Translate> (
                          {vehicleStatus?.offline})
                        </a>
                      </div>
                    </div>
                    <div className="card-body">
                      {vehicleList.length > 0 ? (
                        <MapContainer
                          locations1={trackVehicle}
                          selectVehicleNo={selectVehicleNo}
                        />
                      ) : (
                        <div>No records found</div>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-12 col-lg-6 col-xs-12 mt-3">
                  <div className="card custom-card">
                    <div className="card-body h-240">
                      <div className="d-flex justify-content-between">
                        <h6>
                          <Translate>gpsSubscription</Translate> ({gpsCount})
                        </h6>
                      </div>
                      <div className="pi-chart-wrapper">
                        <ApexChart
                          series={gpsSubs}
                          options={PIOptions}
                          type={"pie"}
                          height={"100%"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6 col-xs-12 mt-3">
                  <div className="card custom-card">
                    <div className="card-body h-240">
                      <div className="d-flex justify-content-between">
                        <h6>
                          <Translate>gpsFuelSubscription</Translate> ({gpsCount}
                          )
                        </h6>
                      </div>
                      <div className="pi-chart-wrapper">
                        <ApexChart
                          series={gpsSubs}
                          options={PIOptions}
                          type={"pie"}
                          height={"100%"}
                        />
                        
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-md-12 col-lg-8 col-xs-8 mt-3">
                  <div className="card custom-card">
                    <div className="card-body">
                      <h6>
                        <Translate>fuelConsumptionReport</Translate>
                      </h6>
                    </div>
                    <div className="pi-chart-wrapper">
                      <ApexChart
                        series={Lineseris}
                        options={LineOptions}
                        type={"area"}
                        height={240}
                      />
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-md-4 col-lg-4 col-xs-4 mt-3 d-block d-md-none d-lg-block">
                  <div className="card custom-card">
                    <div className="card-body h-314">
                      <div className="d-flex justify-content-between">
                        <h6>
                          <Translate>documentExpiration</Translate>
                        </h6>
                        {documentList.length > 0 && <Link to={"/documents"} className="view-all">
                          view all
                        </Link>}
                      </div>
                      <div className="document-list">
                        {documentList.length > 0 && documentList.map((document, index) => (
                          <div className="list" key={`vehicle-detail-${index}`}>
                            <div className="vehicle-details">
                              <p className="mb-1 vehicle-no">
                                {document.vehicleNo}
                              </p>
                              <p className="mb-1 today-km">
                                {getDocumentType(document.documentType)}
                              </p>
                              <p className="mb-1 doc-expired">
                                {moment(document.documentExpirationDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                            </div>
                          </div>
                        ))}
                        {documentList.length <= 0 && <div className="d-flex justify-content-center align-items-center h-100 mt-5">No records </div>}
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Dashboard;
