import React, { useEffect, useState } from "react";
// import AlertsTable from '../../components/AlertsTable';
import ResponsiveTable from "../../components/ResponsiveTable";
import { post } from "../../components/api";
import { SweetAlert } from "../../helpers/helper";
// import DropdownWithCheckboxAndSelectAll from '../../components/DropdownCheckbox';
import Checkbox from "../../components/DropdownCheckbox";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { SiMicrosoftexcel } from "react-icons/si";
import DataTablePagination from "../../components/Datatable";
import DataTablePagination1 from "../../components/paginateTable1";


const Alerts = () => {
  const [alerts, setAlerts] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [alertType, setAlertType] = useState([
    "OVERSPEED",
    "GEOFENCE",
    "IGNITION_ON",
    "IGNITION_OFF",
    "FUEL_FILLED",
    "LOW_FUEL",
  ]);
  const [checkBoxOption, setCheckBoxOption] = useState([]);
  const [checkBoxOptionAlerts, setCheckBoxOptionAlerts] = useState([]);
  const [selectedAlertType, setSelectedAlertType] = useState(null);
  const [startDate, setStart] = useState(moment().startOf("day").toDate());
  const [endDate, setEnd] = useState(moment().toDate());
  const [startDateWithTime, setStartDateWithTime] = useState(
    moment().startOf("day").toDate()
  );
  const [endDateWithTime, setEndDateWithTime] = useState(moment().toDate());
  const today = moment().endOf("day");
  const now = moment();
  const maxSpan = {
    days: 30,
  };
  const [page,setPage] = useState(1);
const [perPage, setPerPage] = useState(20);
const [totalRows, setTotalRows] = useState(0);

  const initialSettings = {
    startDate: startDate,
    endDate: endDate,
    timePicker: true,
    timePicker24Hour: false,
    maxDate: today.toDate(),
    maxTime: now.format("HH:mm"),
    maxSpan: maxSpan,
    locale: {
      // format: 'MMMM D, YYYY HH:mm', // 12-hour format
      format: "MMM DD, YYYY", // 12-hour format
    },
    opens: "left",
    ranges: {
      Today: [moment().startOf("day"), moment()],
      Yesterday: [
        moment().subtract(1, "days").startOf("day"),
        moment().subtract(1, "days").endOf("day"),
      ],
      "Last 7 Days": [moment().subtract(6, "days").startOf("day"), moment()],
      "Last 30 Days": [moment().subtract(29, "days").startOf("day"), moment()],
      "This Month": [
        moment().startOf("month").startOf("day"),
        moment().endOf("month").endOf("day"),
      ],
      "Last Month": [
        moment().subtract(1, "month").startOf("month").startOf("day"),
        moment().subtract(1, "month").endOf("month").endOf("day"),
      ],
    },
  };

  const columns = [
    // {
    //   name: "Id",
    //   selector: "_id",
    //   sortable: true,
    // },
    {
      name: "Type",
      selector: row => row.type,
      sortable: true,
      width: "15%"
    },
    {
      name: "Vehicle No",
      selector: row => row.vehicleNo,
      sortable: true,
      width: "15%"
    },
    {
      name: "Address",
      selector: row => row.address,
      sortable: true,
      width: "50%"
    },
    {
      name: "Created At",
      selector: row => row.createdAt,
      sortable: true,
      // format: (row) => moment(row.createdAt).format("DD MMM, YYYY HH:mm"), // Format date
      width: "20%"
    },
  ];

  const handleApply = (event, picker) => {
    const selectedStart = picker.startDate;
    const selectedEnd = picker.endDate;
    const startDateTime = selectedStart.toISOString();
    const endDateTime = selectedEnd.toISOString();
    setStartDateWithTime(startDateTime);
    setEndDateWithTime(endDateTime);
  };

  useEffect(() => {
    fetchVehicle();
    const option = alertType.map((item) => ({
      label: item,
      value: item,
    }));
    setCheckBoxOptionAlerts(option);
  }, []);

  const fetchVehicle = async () => {
    try {
      const apiResponse = await post("/admin/alerts/Vehicle");
      if (apiResponse?.status === 200) {
        setVehicles(apiResponse.data);
        const option = apiResponse.data.map((item) => ({
          label: item,
          value: item,
        }));
        setCheckBoxOption(option);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let downloadReportLink = "";


  const fetchData = async () => {
    try {
      const apiResponse = await post("/admin/alerts", {
        options: {
          page: page,
          limit: perPage
        },
        query: {
          vehicleNo: vehicles,
          type: alertType,
          startDate: startDateWithTime,
          endDate: endDateWithTime,
        },
      });
      if (apiResponse.status === 200) {
        setAlerts(apiResponse?.data);
        setTotalRows(apiResponse.totalDocs);
        downloadReportLink = apiResponse?.Link;
      } else {
        // SweetAlert(apiResponse.error, true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [vehicles, alertType, startDateWithTime, endDateWithTime, page , perPage]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePerRowsChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const handleVehicleChange = (selected) => {
    setVehicles(selected.map((option) => option.value));
  };

  const handleAlertTypeChange = (selected) => {
    setAlertType(selected.map((option) => option.value));
  };

  let filteredAlerts = selectedAlertType
    ? alerts.filter((alert) => alert.type === selectedAlertType)
    : alerts;

  if (filteredAlerts.length === 0) {
    filteredAlerts.push({
      type: "",
      vehicleNo: "",
      address: "No alerts are for selected alert",
      createdAt: "",
    });
  }
  const handleAlertTypeClick = (type) => {
    // Toggle the selected alert type
    const newType = selectedAlertType === type ? null : type;

    setSelectedAlertType(newType);
    fetchData(newType);
  };

  const alertCounts = alertType.reduce((acc, type) => {
    acc[type] = alerts.filter((alert) => alert.type === type).length || 0;
    return acc;
  }, {});

  return (
    <div className="App" style={{ height: "100vh", padding: "20px" }}>
      <div
        className="row"
        style={{
          padding: "10px",
          marginBottom: "10px",
          display: "flex",
          flexFlow: "row",
          // flexDirection: "row-reverse",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
        <div className="col-md-8 col-lg-8 support-box" style={{}}>
          <div className="card custom-card-setting" style={{ height: "100%" }}>
            <div
              className="card-body"
              style={{ boxShadow: "rgba(0, 0, 0, 0.25) 0px 2px 5px 0px" }}
            >
              <form>
                <div className="row support-page-form">
                  <div className="col-md-6 col-lg-6 col-xs-12 text-left">
                    <label className="required custom-lable-support mb-2">
                      Select Vehicles.
                    </label>
                    <Checkbox
                      options={checkBoxOption}
                      value={checkBoxOption.filter((option) =>
                        vehicles.includes(option.value)
                      )} // Show currently selected vehicles
                      onChange={handleVehicleChange} // Add onChange handler for vehicles
                      name={"vehicles"}
                    />
                  </div>
                  <div className="col-md-6 col-lg-6 col-xs-12 text-left">
                    <label className="required custom-lable-support mb-2">
                      Select Alert Types
                    </label>
                    <Checkbox
                      options={checkBoxOptionAlerts}
                      value={checkBoxOptionAlerts.filter((option) =>
                        alertType.includes(option.value)
                      )} // Show currently selected alert types
                      onChange={handleAlertTypeChange} // Add onChange handler for alert types
                      name={"Alerts"}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-lg-4 support-box">
          <div className="card custom-card-setting" style={{ height: "100%" }}>
            <div
              className="card-body text-left"
              style={{ boxShadow: "rgba(0, 0, 0, 0.25) 0px 2px 5px 0px" }}
            >
              <label className="required custom-lable-support mb-2">
                Select Date.
              </label>
              <DateRangePicker
                className="custom-date-range"
                initialSettings={initialSettings}
                onApply={handleApply}
              >
                <input type="text" className="form-control" />
              </DateRangePicker>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="card" style={{ padding: "20px" }}>
          <div
            style={{
              display: "flex",
              gap: "10px",
              marginBottom: "5px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              {Object.entries(alertCounts).map(([key, value]) => (
                <div
                  key={key}
                  onClick={() => handleAlertTypeClick(key)}
                  style={{
                    fontSize: "10px",
                    color: selectedAlertType === key ? "white" : "black",
                    fontWeight: selectedAlertType === key ? "400" : "400",
                    opacity: 1,
                    // border: selectedAlertType === key ? "1px solid black" : "1px solid green",
                    padding: "6px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    backgroundColor:
                      selectedAlertType === key ? "#2A7C7A" : "white",
                    boxShadow: "rgba(0, 0, 0, 0.25) 0px 2px 5px 0px",
                  }}
                >
                  {key}: {value}
                </div>
                // backgroundColor: "#b0f0f5"
              ))}
            </div>
            <div>
              <a download href={downloadReportLink}>
                <SiMicrosoftexcel size={"20px"} color="green" />
              </a>
            </div>
          </div>
          <hr />
          {/* <ResponsiveTable data={filteredAlerts} /> */}
          <div className="col-md-12 col-lg-12 col-xs-12 karan">
          <DataTablePagination1
            title="Alerts"
            columns={columns}
            data={filteredAlerts}
          totalRows={totalRows}
          page={page}
          perPage={perPage}
          onPageChange={handlePageChange}
          onPerPageChange={handlePerRowsChange}
          />
          </div>
          {/* <DataTablePagination
            title="Alerts"
            columns={columns}
            paginationTotalRows={10}
            apiUrl="/admin/alerts"
            queryObj={{
              vehicleNo: vehicles,
              type: alertType,
              startDate: startDateWithTime,
              endDate: endDateWithTime,
            }}
            pageName="alerts-table"
            updateState={true}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Alerts;
